import { useIsBusinessUser } from "../../entities/helpers/user.helpers";
import { useFeature } from "../features";
import { PERMISSION, useBusinessPermissions } from "../permissions";

const MANAGE_RECIPIENT_PERMISSIONS = [PERMISSION.BUSINESS.WRITE.RECIPIENT];

export const useACHPaymentsPermissions = () => {
  const { featureEnabled } = useFeature();

  const isBusinessUser = useIsBusinessUser();
  const achPaymentsEnabled = featureEnabled("ach_payments");

  return achPaymentsEnabled && isBusinessUser;
};

// referring specifically to ach recipients right now
// but will become universal recipients in future
export const useManageRecipientPermission = () => {
  const [hasManageRecipientPermission] = useBusinessPermissions(
    MANAGE_RECIPIENT_PERMISSIONS,
  );

  return hasManageRecipientPermission;
};
