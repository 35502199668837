import React from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";

import { CheckSquare, Square } from "react-feather"; // eslint-disable-line
import Filters from "byzantine/src/filters";
import { Table } from "semantic-ui-react";

function DualApprovalRequestCheckbox({ isSelected, onToggled }) {
  const Checkbox = isSelected ? CheckSquare : Square;
  return (
    <div style={{ width: "16px" }}>
      <Checkbox
        onClick={onToggled}
        size="16px"
        className={isSelected ? "checkbox checked" : "checkbox"}
      />
    </div>
  );
}
DualApprovalRequestCheckbox.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onToggled: PropTypes.func.isRequired,
};

export default function DualApprovalRequestTable({
  dualApprovalRequests,
  selected,
  select,
  unselect,
}) {
  const { l10n } = useLocalization();
  const isEverythingSelected = () =>
    dualApprovalRequests.every((dar) => selected[dar.uuid]);
  const selectEverything = () =>
    dualApprovalRequests.forEach((dar) => select(dar.uuid));
  const unselectEverything = () =>
    dualApprovalRequests.forEach((dar) => unselect(dar.uuid));

  const toggle = (uuid) => (selected[uuid] ? unselect(uuid) : select(uuid));
  const toggleHeader = () =>
    isEverythingSelected() ? unselectEverything() : selectEverything();

  if (!dualApprovalRequests.length) {
    return (
      <div className="emptyState">
        {l10n.getString("heading-no-pending-approvals")}
      </div>
    );
  }

  return (
    <div className={selected ? "selectable-rows" : ""}>
      <Table className="dual-approval-request-table">
        <Table.Header className="mobile-hidden">
          <Table.Row>
            {selected ? (
              <Table.HeaderCell collapsing>
                <DualApprovalRequestCheckbox
                  isSelected={isEverythingSelected()}
                  onToggled={() => toggleHeader()}
                />
              </Table.HeaderCell>
            ) : null}
            <Table.HeaderCell>{l10n.getString("th-date")}</Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-from")}</Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-to")}</Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-type")}</Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-amount")}</Table.HeaderCell>
            <Table.HeaderCell>
              {l10n.getString("th-submitted-by")}
            </Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-status")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dualApprovalRequests.map((dar) => (
            <Table.Row key={dar.uuid}>
              {selected ? (
                <Table.Cell>
                  <DualApprovalRequestCheckbox
                    isSelected={!!selected[dar.uuid]}
                    onToggled={() => toggle(dar.uuid)}
                  />
                </Table.Cell>
              ) : null}
              <Table.Cell>
                <span className="label mobile-only">{l10n.getString("approval-label-date")}</span>
                {Filters.americanDate(dar.created_at)}
              </Table.Cell>
              <Table.Cell>
                <span className="label mobile-only">{l10n.getString("approval-label-from")}&nbsp;</span>
                {dar.action.from}
              </Table.Cell>
              <Table.Cell>
                <span className="label mobile-only">{l10n.getString("approval-table-to")}&nbsp;</span>
                {dar.action.to}
              </Table.Cell>
              <Table.Cell>
                <span className="label mobile-only">{l10n.getString("approval-table-type")}&nbsp;</span>
                {dar.action.type}
              </Table.Cell>
              <Table.Cell>
                <span className="label mobile-only">{l10n.getString("approval-table-amount")}&nbsp;</span>
                {Filters.currency(dar.action.amount, { hasDecimal: true })}
              </Table.Cell>
              <Table.Cell>
                <span className="label mobile-only">{l10n.getString("approval-table-submitted-by")}&nbsp;</span>
                {dar.requester.getDescription()}
              </Table.Cell>
              <Table.Cell>
                <span className="label mobile-only">{l10n.getString("approval-table-status")}&nbsp;</span>
                {Filters.humanize(dar.action.status)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
DualApprovalRequestTable.propTypes = {
  dualApprovalRequests: PropTypes.array.isRequired,
  selected: PropTypes.object,
  select: PropTypes.func,
  unselect: PropTypes.func,
};
