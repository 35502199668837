/**
 * # Fluent id helpers for institution settings
 *
 * @description
 * All id getters defined in this file match the ids in the output of
 * `to_fluent_messages` setting classmethods.
 *
 * @usage
 * For string type settings, use `getStringSettingFluentId()`.
 *
 * For JSON type settings, one class per setting has id getters.
 */

export const FLUENT_ID_PREFIX = "fi"; // used for all settings

/**
 * @param settingName SETTING_NAME field of setting as hyphenated lowercase
 */
export const getStringSettingFluentId = (settingName: string) => {
  return `${FLUENT_ID_PREFIX}-${settingName}`;
};

/**
 * @description Get translation ids by product id and field
 * @usage
 * ```
 * const CheckingFluentIds = new ProductFluentIds(1234);
 * CheckingFluentIds.getName(); // product name
 * CheckingFluentIds.getDetail(2); // detail by index
 * ```
 */
export class ProductFluentIds {
  private SETTING_NAME = "core-opening-products";

  public productId: string;

  constructor(productId: string) {
    this.productId = productId;
  }

  getName() {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}-${this.productId}-name`;
  }

  getDescription() {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}-${this.productId}-description`;
  }

  getMoreDetailsCopy() {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}-${this.productId}-more-details-copy`;
  }

  getDetail(detailIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}-${this.productId}-details${detailIndex}`;
  }

  getDisclosureCheckboxText(disclosureIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}-${this.productId}-disclosures${disclosureIndex}-checkbox-text`;
  }

  getDisclosureMarkdown(disclosureIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}-${this.productId}-disclosures${disclosureIndex}-markdown`;
  }

  getDisclosureLinkText(disclosureIndex: number, linkIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}-${this.productId}-disclosures${disclosureIndex}-links${linkIndex}-text`;
  }
}

/**
 * @usage
 * ```
 * const fluentIds = new DueDiligenceFluentIds("bao");
 * DueDiligenceFluentIds.getQuestion(0); // first due diligence question in BAO
 * ```
 */
export class DueDiligenceFluentIds {
  private SETTING_NAME: string;

  constructor(aoType: "cao" | "bao") {
    this.SETTING_NAME = `cdd-${aoType}-questions`;
  }

  getQuestion(questionIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}${questionIndex}`;
  }

  getQuestionLabel(questionIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}${questionIndex}-label`;
  }

  getDropdownQuestionOption(questionIndex: number, optionIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}${questionIndex}-options${optionIndex}`;
  }
}

export class SupportContactFluentIds {
  private SETTING_NAME = "support-contacts";

  getTitle(contactIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}${contactIndex}-title`;
  }

  getNumberName(contactIndex: number, numberIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}${contactIndex}-numbers${numberIndex}-name`;
  }
}

export class SupportFaqFluentIds {
  private SETTING_NAME = "support-faq";

  getCategory(categoryIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}${categoryIndex}-category`;
  }

  getQuestion(categoryIndex: number, questionIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}${categoryIndex}-questions${questionIndex}-question`;
  }

  getAnswer(categoryIndex: number, questionIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}${categoryIndex}-questions${questionIndex}-answer`;
  }
}

export class SupportLinksFluentIds {
  private SETTING_NAME = "support-links";

  getText(linkIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}${linkIndex}-text`;
  }
}

export class OaoDisclosuresFluentIds {
  private SETTING_NAME = "oao-disclosures";

  getCheckboxText(disclosureIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}-disclosures${disclosureIndex}-checkbox-text`;
  }

  getMarkdown(disclosureIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}-disclosures${disclosureIndex}-markdown`;
  }

  getLinkText(disclosureIndex: number, linkIndex: number) {
    return `${FLUENT_ID_PREFIX}-${this.SETTING_NAME}-disclosures${disclosureIndex}-links${linkIndex}-text`;
  }
}

type EligibilityModelType = "question" | "answer";
export class AccountEligibilityIds {
  private MODEL_NAME_PREFIX = "account-eligibility";

  private FIELD_NAME = "display-text";

  public modelType: EligibilityModelType;

  constructor(type: EligibilityModelType) {
    this.modelType = type;
  }

  getDisplayText(id: string) {
    return `${FLUENT_ID_PREFIX}-${this.MODEL_NAME_PREFIX}-${this.modelType}-${id}-${this.FIELD_NAME}`;
  }
}
