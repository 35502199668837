export const LIMIT_TYPES = {
  ACH_PUSH: "ach_push",
  ACH_PULL: "ach_pull",
  ACH_PULL_NOW: "immediate_ach_pull",
  RDC: "rdc",
  RDC_NOW: "immediate_rdc",
  WIRE: "wire",
  UNVERIFIED_ACH_PUSH: "unverified_ach_push",
  UNVERIFIED_ACH_PULL: "unverified_ach_pull",
} as const;

export const PRESET_LIMIT_TYPES = {
  transfer: ["ach_pull", "ach_push", "immediate_ach_pull"],
  wire: ["wire"],
  achPayments: ["unverified_ach_push", "unverified_ach_pull"],
} satisfies Record<string, API.Limits.Type[]>;
