export const getFluentIdForSECCode = (secCode: API.ACHPayment.SECCode) => {
  switch (secCode) {
    case "CCD":
      return {
        id: "ccd-payment-description",
        vars: {},
        fallback:
          "A CCD entry can be either a buyer-initiated or seller-initiated transaction used to move funds between the buyer’s and seller’s financial institution accounts. It is also used by companies to move funds from outlying depository locations to a central bank account. A CCD entry supports including one addenda and is typically used when paying for one item.",
      } as const;
    case "CIE":
      return {
        id: "cie-payment-description",
        vars: {},
        fallback:
          "A CIE entry is a credit entry initiated on behalf of, and upon the instruction of, a consumer to transfer funds to a non-consumer Receiver.",
      } as const;
    case "CTX":
      return {
        id: "ctx-payment-description",
        vars: {},
        fallback:
          "A CTX entry is similar to a CCD entry, but it supports including multiple addenda.",
      } as const;
    case "IAT":
      return {
        id: "iat-payment-description",
        vars: {},
        fallback:
          "An IAT entry is a debit or credit entry that is part of a payment transaction involving a financial agency’s office that is not located in the territorial jurisdiction of the United States.",
      } as const;
    case "PPD":
      return {
        id: "ppd-payment-description",
        vars: {},
        fallback:
          "A PPD entry is a credit or debit entry originated by an organization to a consumer’s account, based on standing or single-entry authorization from that consumer. A PPD entry supports including one addenda.",
      } as const;
    case "WEB":
      return {
        id: "web-payment-description",
        vars: {},
        fallback:
          "WEB entries are entries transmitted to a consumer Receiver’s account. These entries can be either debits or credits. WEB entries may be either recurring or non-recurring transactions.",
      } as const;
    default:
      return undefined;
  }
};
