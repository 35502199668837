import { savvyMoneyAdapter } from "./slice";

import type { RootState } from "../store";
import type { SavvyMoneyViewUrl } from "./types";

const selectSavvyMoneyState = (state: RootState) => state.savvyMoney;

const { selectById: selectSavvyMoneyUrlByView } =
  savvyMoneyAdapter.getSelectors(selectSavvyMoneyState);

export const useSelectSavvyMoneyUrlByView =
  (view: SavvyMoneyViewUrl) => (state: RootState) => {
    const savvyMoneyUrl = selectSavvyMoneyUrlByView(state, view);
    return savvyMoneyUrl ? savvyMoneyUrl.url : null;
  };
