import { createSlice } from "@reduxjs/toolkit";

import { adapter } from "./adapter";

const name = "achCompanies";

const slice = createSlice({
  name,
  initialState: adapter.getInitialState(),
  reducers: {
    setMany: adapter.setMany,
  },
});

export const { actions } = slice;

export default {
  [name]: slice.reducer,
};
