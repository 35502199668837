import { useCallback } from "react";

import { useDispatch } from "react-redux";

import { useObjectMemo } from "../../../hooks";
import { useRequestWithFeedback } from "../../../composites";
import { useLibrary } from "../../../providers";

import CardLimitsAPI from "./api";
import { actions as cardLimitsActions } from "./slice";

import type { ReactLocalization } from "@fluent/react";
import type { OverrideRunProps } from "../../../composites";
import type {
  CardLimitsRequestParams,
  UpdateLimitsPayload,
  CardLimitsUpdateParams,
} from "./types";

type CardControlsToastFluentId = `card-controls-toast-${string}`;

const DEFAULT_CARD_LIMITS_MESSAGES: Record<CardControlsToastFluentId, string> =
  {
    "card-controls-toast-success-update-card-limits": "Limits increased.",
    "card-controls-toast-success-update-pos-limit": "Spending limit increased.",
    "card-controls-toast-success-update-atm-limit":
      "ATM withdrawal limit increased.",
    "card-controls-toast-error-update-card-limits":
      "Limits could not be increased.",
    "card-controls-toast-error-update-pos-limit":
      "Spending limit could not be increased.",
    "card-controls-toast-error-update-atm-limit":
      "ATM withdrawal limit could not be increased.",
    "card-controls-toast-error-get-card-limits":
      "We could not open this feature. Please try again.",
  } as const;

const getMessageOnLimitType = (
  newLimits: UpdateLimitsPayload,
  messageType: "error" | "success",
  card: API.Card,
  t: ReactLocalization,
) => {
  let messageFluentId: CardControlsToastFluentId;

  if (!newLimits.atmLimit && newLimits.posLimit) {
    messageFluentId =
      messageType === "success"
        ? "card-controls-toast-success-update-pos-limit"
        : "card-controls-toast-error-update-pos-limit";
  } else if (newLimits.atmLimit && !newLimits.posLimit) {
    messageFluentId =
      messageType === "success"
        ? "card-controls-toast-success-update-atm-limit"
        : "card-controls-toast-error-update-atm-limit";
  } else {
    messageFluentId =
      messageType === "success"
        ? "card-controls-toast-success-update-card-limits"
        : "card-controls-toast-error-update-card-limits";
  }

  const defaultToastMessage = DEFAULT_CARD_LIMITS_MESSAGES[messageFluentId];
  return t
    ? t.getString(
        messageFluentId,
        { cardName: `${card.name} *${card.last_four_digits}` },
        defaultToastMessage,
      )
    : defaultToastMessage;
};

export const useGetCardLimits = ({
  cardId,
  onSuccess,
  onError,
  onData,
}: CardLimitsRequestParams) => {
  const dispatch = useDispatch();
  const t = useLibrary("translations");
  const { send: requestSend, loading } =
    useRequestWithFeedback<API.CardLimits>();

  const send = useCallback(
    (overrideRunProps?: OverrideRunProps<API.CardLimits>) => {
      requestSend({
        action: CardLimitsAPI.getCardLimits(cardId),
        messaging: {
          toast: {
            error: t
              ? t.getString(
                  "card-controls-toast-error-get-card-limits",
                  {},
                  DEFAULT_CARD_LIMITS_MESSAGES[
                    "card-controls-toast-error-get-card-limits"
                  ],
                )
              : DEFAULT_CARD_LIMITS_MESSAGES[
                  "card-controls-toast-error-get-card-limits"
                ],
          },
        },
        onError,
        onSuccess,
        onData: (cardLimits) => {
          dispatch(
            cardLimitsActions.upsertOne({ id: cardId, limits: cardLimits }),
          );
          onData?.(cardLimits);
        },
        ...overrideRunProps,
      });
    },
    [requestSend, cardId, t, onError, onSuccess, dispatch, onData],
  );

  return useObjectMemo({ send, loading });
};

export const useUpdateCardLimits = ({
  card,
  onSuccess,
  onError,
}: CardLimitsUpdateParams) => {
  const t = useLibrary("translations");
  const dispatch = useDispatch();
  const { send: requestSend, loading } =
    useRequestWithFeedback<API.CardLimits>();

  const send = useCallback(
    (
      newLimits: UpdateLimitsPayload,
      overrideRunProps?: OverrideRunProps<API.CardLimits>,
    ) => {
      if (!newLimits.atmLimit && !newLimits.posLimit) {
        onError?.();
        return;
      }

      requestSend({
        action: CardLimitsAPI.updateCardLimits(card.id, newLimits),
        messaging: {
          toast: {
            success: getMessageOnLimitType(newLimits, "success", card, t),
            error: getMessageOnLimitType(newLimits, "error", card, t),
          },
        },
        onError,
        onSuccess,
        onData: (cardLimits) => {
          dispatch(
            cardLimitsActions.upsertOne({ id: card.id, limits: cardLimits }),
          );
        },
        ...overrideRunProps,
      });
    },
    [requestSend, card, t, onSuccess, onError, dispatch],
  );

  return useObjectMemo({ send, loading });
};
