import { ReactNode } from "react";

import {
  LoadingContextProvider,
  NotificationContextProvider,
  GliaVisibilityProvider,
  GliaVisibilityHandler,
} from "cerulean";

import type { PreloadedState } from "byzantine";
import AppLocalizationProvider, {
  AppLocalizationProviderProps,
} from "../AppLocalizationProvider";
import {
  CurrentUserContextProvider,
  CurrentUserContextProviderProps,
} from "../contexts/CurrentUserContext";
import { UserFeaturesContextProvider } from "../contexts/UserFeaturesContext";

import DBBLLibraryProvider from "../../src/libraries/library.provider";

interface AppProvidersProps
  extends AppLocalizationProviderProps,
    CurrentUserContextProviderProps {
  preloadedState: PreloadedState;
  children: ReactNode;
}

const AppProviders = ({
  currentUser,
  internalName,
  s3ImagesBucket,
  preloadedState,
  children,
}: AppProvidersProps) => (
  <AppLocalizationProvider
    internalName={internalName}
    s3ImagesBucket={s3ImagesBucket}
  >
    <NotificationContextProvider>
      <LoadingContextProvider>
        <UserFeaturesContextProvider>
          <CurrentUserContextProvider currentUser={currentUser}>
            <DBBLLibraryProvider preloadedState={preloadedState}>
              <GliaVisibilityProvider>
                <GliaVisibilityHandler />
                {children}
              </GliaVisibilityProvider>
            </DBBLLibraryProvider>
          </CurrentUserContextProvider>
        </UserFeaturesContextProvider>
      </LoadingContextProvider>
    </NotificationContextProvider>
  </AppLocalizationProvider>
);

export default AppProviders;
