import { useEffect, useRef } from "react";
import { entities } from "byzantine";
import { useNavigate } from "react-router-dom";
import { useLocalization } from "@fluent/react";

import { LoadingShim, useNotificationContext } from "cerulean";

import styles from "./SavvyMoneyDashboard.module.scss";

interface SavvyMoneyDashboardProps {
  features: API.Features;
}

const SavvyMoneyDashboard = ({ features }: SavvyMoneyDashboardProps) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();
  const navigate = useNavigate();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { send: fetchSavvyMoneyRedirectUrl, error: hasErrored } =
    entities.savvyMoney.useFetchSavvyMoneyRedirectUrl(
      entities.savvyMoney.SavvyMoneyViewUrl.WebDashboard,
      Boolean(features.olb_enable_credit_monitor),
    );

  const url = entities.savvyMoney.useSavvyMoneyUrl(
    entities.savvyMoney.SavvyMoneyViewUrl.WebDashboard,
  );

  if (hasErrored) {
    sendNotification({
      type: "negative",
      text: l10n.getString("savvy-money-full-experience-error"),
    });

    navigate("/", { replace: true });
  }

  useEffect(() => {
    fetchSavvyMoneyRedirectUrl();
  }, [fetchSavvyMoneyRedirectUrl]);

  useEffect(() => {
    if (!url) {
      // Empty clean up function
      return () => {};
    }

    // Extract the allowed origin from the URL
    const allowedOrigin = new URL(url).origin;

    const handleMessage = (e: MessageEvent) => {
      // Validate message origin
      if (e.origin !== allowedOrigin) {
        return;
      }

      // Ensure e.data is an object
      if (typeof e.data !== "object" || e.data === null) {
        return;
      }

      const { task, isSmoothScrollNotSupported, offset, height } = e.data;
      const iframe = iframeRef.current;

      switch (task) {
        case "scrollTo":
          if (typeof offset === "number") {
            window.scrollTo({
              top: offset,
              behavior: isSmoothScrollNotSupported ? "auto" : "smooth",
            });
          }
          break;

        case "scrollToTop":
          window.scrollTo({
            top: 0,
            behavior: isSmoothScrollNotSupported ? "auto" : "smooth",
          });
          break;

        case "resize":
          if (typeof height === "number" && iframe) {
            iframe.style.height = `${height}px`;
            iframe.contentWindow?.postMessage(
              { task: "setAutosizeClassName" },
              allowedOrigin,
            );
          }
          break;

        default:
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [url]);

  return url ? (
    <div className={styles.container}>
      <iframe
        ref={iframeRef}
        title="SavvyMoney"
        src={url}
        style={{ width: "100%", height: "337px", border: "none" }}
      />
    </div>
  ) : (
    <LoadingShim isLoading>
      <div style={{ height: "100vh" }} />
    </LoadingShim>
  );
};

export default SavvyMoneyDashboard;
