import type { NetworkPayload } from "../../../composites";

export const API = {
  createACHPayment: (
    idempotencyKey: UUID,
    {
      amount,
      institution_account,
      ach_company,
      std_ent_cls_code,
      entry_desc,
      recipient,
    }: API.CreateACHPayment.Request,
  ): NetworkPayload => {
    return {
      url: "ach_payments/",
      options: {
        method: "POST",
        payload: {
          amount,
          institution_account,
          ach_company,
          std_ent_cls_code,
          entry_desc,
          recipient,
        },
        idempotencyKey,
      },
    };
  },
};
