// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reviewCardContainer--eCcYJ{border-radius:var(--space-xs);border:1px solid var(--color-lightGrey);padding:calc(var(--space-xl) - var(--space-xs));background-color:var(--color-white)}.reviewCardHeader--Xg2zZ{margin-bottom:var(--space-l)}.reviewCardSection--Jmm1E{border-top:1px solid var(--color-lightGrey);padding:var(--space-l) 0px}", "",{"version":3,"sources":["webpack://./components/transfer/ach/ReviewScreen.module.scss"],"names":[],"mappings":"AAAA,4BACE,6BAAA,CACA,uCAAA,CACA,+CAAA,CACA,mCAAA,CAGF,yBACE,4BAAA,CAGF,0BACE,2CAAA,CACA,0BAAA","sourcesContent":[".reviewCardContainer {\n  border-radius: var(--space-xs);\n  border: 1px solid var(--color-lightGrey);\n  padding: calc(var(--space-xl) - var(--space-xs));\n  background-color: var(--color-white);\n}\n\n.reviewCardHeader {\n  margin-bottom: var(--space-l);\n}\n\n.reviewCardSection {\n  border-top: 1px solid var(--color-lightGrey);\n  padding: var(--space-l) 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reviewCardContainer": "reviewCardContainer--eCcYJ",
	"reviewCardHeader": "reviewCardHeader--Xg2zZ",
	"reviewCardSection": "reviewCardSection--Jmm1E"
};
export default ___CSS_LOADER_EXPORT___;
