import { useState, useEffect } from "react";

// locationchange event is a custom event dispatched by the createLocationChangeEvent function

const usePathname = () => {
  const [pathname, setPathname] = useState(window.location.pathname);

  useEffect(() => {
    const handleLocationChange = () => {
      setPathname(window.location.pathname);
    };

    window.addEventListener("locationchange", handleLocationChange);

    return () => {
      window.removeEventListener("locationchange", handleLocationChange);
    };
  }, []);

  return pathname;
};

export default usePathname;
