import React from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import { featureEnabled, featureEquals } from "byzantine/src/Feature";
import { modules } from "byzantine";

import { WireTransfer } from "../../src";
import { AccountContextProvider } from "../contexts/AccountContext";
import { InstitutionSettingsContextProvider } from "../contexts/InstitutionSettingsContext";
import TransferMenu from "./TransferMenu";
import SimpleTransferRouter from "./SimpleTransferRouter";
import BillPay from "./BillPay";
import M2MTransfer from "./M2MTransfer";
import JhaZelle from "./JhaZelle";
import GenericZelle, { ZelleUrl } from "./GenericZelle";
import CustomizableTransferMenu from "./CustomizableTransferMenu";
import LoanPaymentRouter from "./loans/LoanPaymentRouter";
import ScrollToTopOnRouteChange from "../ScrollToTopOnRouteChange";
import { SudoProvider, SudoRoutes } from "../SudoContext";
import RequestOTP from "../SudoContext/RequestOTP";
import SubmitOTP from "../SudoContext/SubmitOTP";
import WireTransferActionReview from "./wires/review/WireTransferActionReview";
import ACHPaymentRoutes, { ACH_PAYMENT_ROUTE } from "./ach/ACHPaymentRoutes";

const TransferPageContainer = ({ accounts, limitsJson, features }) => {
  const limits = JSON.parse(limitsJson);
  const { showWireTransferOptionInMenu: canNavigateToWiresFlow } =
    modules.wireTemplates.useWirePermissions();

  return (
    <AccountContextProvider accounts={[...accounts]}>
      <InstitutionSettingsContextProvider>
        <Router basename="/transfer">
          <SudoProvider>
            <ScrollToTopOnRouteChange />
            <Routes>
              {featureEnabled(features, {
                or: ["internal_transfers", "ach"],
              }) && (
                <Route
                  path="/funds"
                  element={
                    <div className="transfer-page-layout">
                      <div className="transfer-page">
                        <SimpleTransferRouter limits={limits} />
                      </div>
                    </div>
                  }
                />
              )}
              {featureEnabled(features, { or: ["bill_pay"] }) && (
                <Route
                  path="/bill_pay"
                  element={
                    <div className="transfer-page-layout">
                      <div className="transfer-page">
                        <BillPay />
                      </div>
                    </div>
                  }
                />
              )}
              {featureEquals(features, "p2p", "m2m") && (
                <Route
                  path="/m2m"
                  element={
                    <div className="transfer-page-layout">
                      <div className="transfer-page">
                        <M2MTransfer />
                      </div>
                    </div>
                  }
                />
              )}
              <Route
                path={`${SudoRoutes.RequestOTP}`}
                element={<RequestOTP />}
              />
              <Route path={`${SudoRoutes.SubmitOTP}`} element={<SubmitOTP />} />
              <Route
                path="/wires/review"
                element={<WireTransferActionReview />}
              />
              {canNavigateToWiresFlow && (
                <Route
                  path="/wires/*"
                  element={<WireTransfer limits={limits} />}
                />
              )}
              {featureEnabled(features, { or: ["zelle"] }) && (
                <Route
                  path="/zelle"
                  element={
                    <div className="transfer-page-layout">
                      <div className="transfer-page">
                        {(featureEquals(features, "zelle", true) ||
                          featureEquals(features, "zelle", "jha")) && (
                          <JhaZelle />
                        )}
                        {featureEquals(features, "zelle", "fis") && (
                          <GenericZelle url={ZelleUrl.FIS} />
                        )}
                        {featureEquals(features, "zelle", "fiserv") && (
                          <GenericZelle url={ZelleUrl.FISERV} />
                        )}
                      </div>
                    </div>
                  }
                />
              )}
              {featureEnabled(features, {
                or: ["internal_transfers", "ach"],
              }) &&
              featureEnabled(features, {
                or: ["loan_principal_payment", "loan_payment_by_card"],
              }) ? (
                <Route
                  path="/loans"
                  element={
                    <div className="transfer-page-layout">
                      <div className="transfer-page">
                        <LoanPaymentRouter limits={limits} />
                      </div>
                    </div>
                  }
                />
              ) : null}
              {featureEnabled(features, {
                or: ["ach_payments"],
              }) ? (
                <Route
                  path={`/${ACH_PAYMENT_ROUTE}/*`}
                  element={
                    <div className="transfer-page-layout">
                      <div className="transfer-menu">
                        <ACHPaymentRoutes limits={limits} />
                      </div>
                    </div>
                  }
                />
              ) : null}
              <Route
                path="/"
                element={
                  <div className="transfer-page-layout">
                    <div className="transfer-menu">
                      {features.beta_naf_transfer_option ? (
                        <CustomizableTransferMenu />
                      ) : (
                        <TransferMenu />
                      )}
                    </div>
                  </div>
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </SudoProvider>
        </Router>
      </InstitutionSettingsContextProvider>
    </AccountContextProvider>
  );
};

TransferPageContainer.propTypes = {
  accounts: PropTypes.array,
  institution: PropTypes.object,
  payees: PropTypes.array,
  limitsJson: PropTypes.string,
  features: PropTypes.object,
};

export default TransferPageContainer;
