import { createContext } from "react";

import type { ReactLocalization } from "@fluent/react";

export const TranslationsContext = createContext<ReactLocalization | undefined>(
  undefined,
);

export type TranslationsProps = {
  translations?: ReactLocalization;
};
