import { useMemo } from "react";
import { matchRoutes } from "react-router-dom";
import { useLocalization } from "@fluent/react";
import { DepositInsurer } from "byzantine/src/types";
import { catcat as cc } from "cerulean";

import usePathname from "../hooks/usePathname";
import {
  ACH_PAYMENT_ROUTE,
  ACH_PAYMENT_REVIEW_ROUTE,
} from "../transfer/ach/ACHPaymentRoutes";
import fdicLogo from "../../assets/images/fdic.svg";
import styles from "./FdicBanner.module.scss";

interface FdicBannerProps {
  depositInsurer?: DepositInsurer;
}

const FDIC_ROUTES = [
  { path: "/login" },
  { path: "/transfer/funds" },
  { path: `/transfer/${ACH_PAYMENT_ROUTE}` },
  { path: `/transfer/${ACH_PAYMENT_ROUTE}/${ACH_PAYMENT_REVIEW_ROUTE}` },
];

const FdicBanner = ({ depositInsurer }: FdicBannerProps) => {
  const { l10n } = useLocalization();
  const pathname = usePathname();
  const isFdicInsuredRoute = useMemo(() => {
    const matches = matchRoutes(FDIC_ROUTES, pathname);
    return matches !== null && matches.length > 0;
  }, [pathname]);
  const showFidcBanner =
    depositInsurer === DepositInsurer.FDIC && isFdicInsuredRoute;

  if (!showFidcBanner) {
    return null;
  }

  return (
    <div className={styles.background}>
      <div className={cc("nds-container narmi-grid", styles.container)}>
        <div className={styles.wrapper}>
          <img
            src={fdicLogo}
            alt="FDIC"
            aria-label={l10n.getString("fdic-banner-logo-text")}
          />
          <div className={styles.text}>
            {l10n.getString("fdic-banner-logo-text")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FdicBanner;
