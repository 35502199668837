import { createDeepEqualSelector } from "../utils/createDeepEqualSelector";

import type { RootState } from "../store";

// state.user is never undefined in practice, but may be undefined
// in the jest testing environment
export const selectUser = (state: RootState) => state.user?.user;

export const isBusinessUser = createDeepEqualSelector(
  selectUser,
  (user) => !!(user?.org_role && user?.org_uuid),
);

export const businessPermissions = createDeepEqualSelector(
  selectUser,
  (user) => user?.business_permissions || [],
);

export const dualApprovalRequired = createDeepEqualSelector(
  selectUser,
  (user) => user?.dual_approval_required || false,
);
