import * as Yup from "yup";

import { createForm } from "../../../forms";
import finance from "../../../../../finance";
import { ACCOUNT_TYPE_OPTIONS } from "../consts";

export type ACHDestinationFormType = {
  accountNumber: string;
  routingNumber: string;
  accountType: string;
};

const validationSchema = Yup.object().shape({
  accountNumber: Yup.string().required("Required").max(34),
  routingNumber: Yup.string()
    .required("Required")
    .test(
      "length",
      "Please enter a valid 9-digit routing number.",
      (val) => val.length === 9,
    )
    .test("valid", "Please enter a valid 9-digit routing number.", (val) => {
      const isValid = finance.abaRoutingNumberIsValid(val);
      return isValid;
    }),
  accountType: Yup.string().required("Required"),
});

export const achDestinationInitialValues: ACHDestinationFormType = {
  accountNumber: "",
  routingNumber: "",
  accountType: "",
};

const PERSONAL = "personal" as API.ACHDestination["account_purpose"];
const BUSINESS = "business" as API.ACHDestination["account_purpose"];
const CHECKING = "checking" as API.ACHDestination["account_type"];
const SAVINGS = "savings" as API.ACHDestination["account_type"];

// we only display accountNumber, routingNumber and accountType in the form
export const transformACHApiFieldsToFormFields = ({
  account_number: accountNumber,
  routing_number: routingNumber,
  account_type: type,
  account_purpose: accountPurpose,
}: API.ACHDestination) => {
  let accountType;
  if (accountPurpose === PERSONAL) {
    accountType =
      type === CHECKING
        ? ACCOUNT_TYPE_OPTIONS.PERSONAL_CHECKING
        : ACCOUNT_TYPE_OPTIONS.PERSONAL_SAVINGS;
  } else if (accountPurpose === BUSINESS) {
    accountType =
      type === CHECKING
        ? ACCOUNT_TYPE_OPTIONS.BUSINESS_CHECKING
        : ACCOUNT_TYPE_OPTIONS.BUSINESS_SAVINGS;
  }

  return {
    accountNumber,
    routingNumber,
    accountType,
  } as ACHDestinationFormType;
};

export const transformACHFormFieldsToApiFields = ({
  accountNumber: account_number,
  routingNumber: routing_number,
  accountType: type,
  recipientId,
}: ACHDestinationFormType & { recipientId: API.RecipientId }) => {
  let accountType;
  let accountPurpose = "";

  if (
    type === ACCOUNT_TYPE_OPTIONS.BUSINESS_CHECKING ||
    type === ACCOUNT_TYPE_OPTIONS.PERSONAL_CHECKING
  ) {
    accountType = CHECKING;
    accountPurpose =
      type === ACCOUNT_TYPE_OPTIONS.BUSINESS_CHECKING ? BUSINESS : PERSONAL;
  } else if (
    type === ACCOUNT_TYPE_OPTIONS.BUSINESS_SAVINGS ||
    type === ACCOUNT_TYPE_OPTIONS.PERSONAL_SAVINGS
  ) {
    accountType = SAVINGS;
    accountPurpose =
      type === ACCOUNT_TYPE_OPTIONS.BUSINESS_SAVINGS ? BUSINESS : PERSONAL;
  }

  return {
    account_number,
    routing_number,
    account_type: accountType,
    account_purpose: accountPurpose,
    recipient: recipientId,
  } as API.CreateUpdateACHDestination.Request;
};

// define form schema and validations
export const ACHDestinationForm = createForm({
  initialValues: achDestinationInitialValues,
  validationSchema,
});
