import { sortBy } from "lodash";

import { createDeepEqualSelector } from "../utils/createDeepEqualSelector";

import type { RootState } from "../store";

const selectFromState = (state: RootState) => state.fedwireTemplates.byId;

export const selectFedwireTemplates = createDeepEqualSelector(
  selectFromState,
  (byId) => Object.values(byId),
);

export const selectOneFedwireTemplate = createDeepEqualSelector(
  [
    selectFromState,
    (_, fedWireTemplateId?: API.FedwireTemplateId) => fedWireTemplateId,
  ],
  (byId, fedWireTemplateId) => {
    return fedWireTemplateId ? byId[fedWireTemplateId] : undefined;
  },
);

export const selectSortedFedwireTemplateIds = createDeepEqualSelector(
  [selectFedwireTemplates],
  (templates) => {
    return sortBy(templates, (t) => t.last_used ?? -1)
      .reverse()
      .map((t) => t.id);
  },
);
