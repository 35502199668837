import React, { ReactNode, useRef } from "react";
import { Drawer as NdsDrawer } from "@narmi/design_system";
import { useSetGliaVisibility } from "../GliaVisibilityContext";

interface DrawerProps {
  isOpen: boolean;
  onUserDismiss?: () => void;
  onNext?: () => void;
  onPrev?: () => void;
  showClose?: boolean;
  showControls?: boolean;
  position?: string;
  depth?: string;
  paddingSize?: string;
  testId?: string;
  children: ReactNode;
}
const Drawer = ({
  isOpen,
  onUserDismiss,
  onNext,
  onPrev,
  showClose,
  showControls,
  position,
  depth,
  paddingSize,
  testId,
  children,
}: DrawerProps) => {
  const drawerIdRef = useRef(`Drawer-${Math.random().toString(36).substr(2, 9)}`);
  useSetGliaVisibility(drawerIdRef.current, !isOpen);

  return (
    <NdsDrawer
      isOpen={isOpen}
      onUserDismiss={onUserDismiss}
      onNext={onNext}
      onPrev={onPrev}
      showClose={showClose}
      showControls={showControls}
      position={position}
      depth={depth}
      paddingSize={paddingSize}
      testId={testId}
    >
      {/* TODO eventually put all modal logic together? */}
      {children}
    </NdsDrawer>
  );
};

export default Drawer;
