import type { UpdateLimitsPayload } from "./types";
import type { NetworkPayload } from "../../../composites";

const resourceUrl = "cards";
const CardLimitsAPI = {
  getCardLimits: (id: API.CardId): NetworkPayload => {
    return {
      url: `${resourceUrl}/${id}/limits/`,
      options: {
        method: "GET",
      },
    };
  },

  updateCardLimits: (
    id: API.CardId,
    { atmLimit, posLimit }: UpdateLimitsPayload,
  ): NetworkPayload => {
    return {
      url: `${resourceUrl}/${id}/limits/`,
      options: {
        method: "POST",
        payload: {
          ...(atmLimit ? { atm_limit: atmLimit } : {}),
          ...(posLimit ? { pos_limit: posLimit } : {}),
        },
      },
    };
  },
};

export default CardLimitsAPI;
