import React, { useCallback } from "react";
import { ContentCard, IconButton, useNotificationContext } from "cerulean";
import { modules } from "byzantine";
import Feature from "byzantine/src/Feature";
import { Link } from "react-router-dom";
import { useLocalization } from "@fluent/react";
import type { InstitutionFeatures } from "byzantine/src/types";
import { CARD_LIMIT_TYPE } from "byzantine/src/dbbl/businessLogic/entities/cardLimitIncrease";
import { useUserFeatures } from "../../../../../contexts/UserFeaturesContext";
import styles from "./CardActions.module.scss";

const DamagedWrapper = ({
  cardId,
  isDamaged,
  onUserDismiss,
  children,
}: {
  cardId: API.Card["id"];
  isDamaged: boolean;
  onUserDismiss: () => void;
  children: React.ReactElement;
}) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();

  const onDamagedReplacementClick = useCallback(() => {
    sendNotification({
      type: "negative",
      text: l10n.getString("card-feature-replace-damaged-error"),
    });
    onUserDismiss();
  }, []);

  return !isDamaged ? (
    <Link
      to={`/cards/replace-a-card/${cardId}/reason`}
      className={styles.replaceCardContainer}
    >
      {children}
    </Link>
  ) : (
    <div
      onClick={onDamagedReplacementClick}
      className={styles.replaceCardContainer}
      aria-label="Replace Card - Replacing a recently replaced damaged card is not allowed."
    >
      {children}
    </div>
  );
};

interface CardActionsProps {
  card: API.Card;
  onUserDismiss: () => void;
}

const CardActions = ({ card, onUserDismiss }: CardActionsProps) => {
  const { l10n } = useLocalization();
  const features = useUserFeatures() as InstitutionFeatures;
  const isDamaged = card.state === "damaged";
  const isNormal = card.state === "normal";

  const limitsEnabled = [
    { feature: features.olb_enable_card_atm_limit, type: CARD_LIMIT_TYPE.ATM },
    { feature: features.olb_enable_card_pos_limit, type: CARD_LIMIT_TYPE.POS },
  ]
    .filter((item) => item.feature)
    .map((item) => item.type);

  const hasLimitIncreaseFeature =
    modules.cardLimitIncrease.hasLimitIncreaseFeature(limitsEnabled, card);

  return (
    <ContentCard kind="elevated">
      <DamagedWrapper
        cardId={card.id}
        isDamaged={isDamaged}
        onUserDismiss={onUserDismiss}
      >
        <>
          <div className={styles.titleContainer}>
            <span className={styles.title}>
              {l10n.getString("card-feature-replace")}
            </span>
            <span aria-hidden="true" className={styles.iconContainer}>
              <IconButton name="arrow-right" kind="plain" textSize="l" />
            </span>
          </div>
          <p
            className={`fontColor--secondary fontSize--s ${styles.description}`}
          >
            {l10n.getString("card-feature-replace-description")}
          </p>
        </>
      </DamagedWrapper>
      {isNormal && (
        <Feature features={features} or={["card_travel_notes"]}>
          <Link
            to={`/cards/${card.id}/travel_notice`}
            className={styles.replaceCardContainer}
          >
            <div className={styles.titleContainer}>
              <span className={styles.title}>
                {l10n.getString("card-feature-travel-notes")}
              </span>
              <span aria-hidden="true" className={styles.iconContainer}>
                <IconButton name="arrow-right" kind="plain" textSize="l" />
              </span>
            </div>
            <p
              className={`fontColor--secondary fontSize--s ${styles.description}`}
            >
              {l10n.getString("card-feature-travel-notes-description")}
            </p>
          </Link>
        </Feature>
      )}
      {hasLimitIncreaseFeature && (
        <Link
          to={`/cards/${card.id}/limit_increase`}
          className={styles.replaceCardContainer}
        >
          <div className={styles.titleContainer}>
            <span className={styles.title}>
              {l10n.getString("card-feature-limits")}
            </span>
            <span aria-hidden="true" className={styles.iconContainer}>
              <IconButton name="arrow-right" kind="plain" textSize="l" />
            </span>
          </div>
          <p
            className={`fontColor--secondary fontSize--s ${styles.description}`}
          >
            {l10n.getString("card-feature-limits-description")}
          </p>
        </Link>
      )}
    </ContentCard>
  );
};

export default CardActions;
