import {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
  ReactNode,
} from "react";
import { entities } from "byzantine";
import { DRAWER_TYPES } from "./RecipientDrawer";

type ValidNotSavedSectionsType = {
  [key in "profile" | "ach" | "wire"]: boolean;
};

interface DrawerContextType {
  triggerValidations: () => {
    isValid: boolean;
  };
  handleSaveProfile: () => void;
  validNotSavedSections: ValidNotSavedSectionsType;
}

interface DrawerProviderProps {
  children: ReactNode;
  drawerType: string;
  recipientId?: API.RecipientId;
}

const defaultDrawerContext: DrawerContextType = {
  triggerValidations: () => ({
    isValid: true,
  }),
  handleSaveProfile: () => {},
  validNotSavedSections: {
    profile: false,
    ach: false,
    wire: false,
  },
};

const DrawerContext = createContext<DrawerContextType>(defaultDrawerContext);
export const useDrawerContext = () => useContext(DrawerContext);

// this provider handles all the checking of validations for opening/closing a section
// if user enters any values in a section, tries to close out of it and it is invalid, we show the form errors. if it is valid, we display an info banner
// in add drawer, if user clicks into any section but hasn't saved profile section, we either show form errors or info banner in profile section
export const DrawerValidationsProvider = ({
  children,
  drawerType,
  recipientId,
}: DrawerProviderProps) => {
  const [isProfileSaved, setIsProfileSaved] = useState(false);
  const [validNotSavedSections, setValidNotSavedSections] = useState(
    defaultDrawerContext.validNotSavedSections,
  );
  // the 3 forms that make up this drawer :sweat-face:
  const { form: profileForm } =
    entities.recipients.useRecipientProfileForm(recipientId);
  const { form: achForm } =
    entities.recipients.useACHDestinationForm(recipientId);
  const { form: wireForm } =
    entities.recipients.useWireDestinationForm(recipientId);
  const { isValid: profileValid } = profileForm;
  const { isValid: achValid } = achForm;
  const { isValid: wireValid } = wireForm;
  const isAddDrawer = drawerType === DRAWER_TYPES.add;

  useEffect(() => {
    setIsProfileSaved(false);
    setValidNotSavedSections(defaultDrawerContext.validNotSavedSections);
  }, []);

  const isAchEmpty = () => {
    const { values } = achForm;
    const { accountNumber, routingNumber, accountType } = values;

    return (
      Boolean(!accountNumber) &&
      Boolean(!routingNumber) &&
      Boolean(!accountType)
    );
  };

  const isWireEmpty = () => {
    const { values } = wireForm;
    const { accountNumber, routingNumber, address } = values;
    const { streetAddress, streetAddress2, city, postalCode } = address;

    // we are purposely not checking aginst regionCode
    // so a user can toggle this section closed if everything is blank but regionCode
    return (
      Boolean(!accountNumber) &&
      Boolean(!routingNumber) &&
      Boolean(!streetAddress) &&
      Boolean(!streetAddress2) &&
      Boolean(!city) &&
      Boolean(!postalCode)
    );
  };

  const isProfileEmpty = () => {
    const { values } = profileForm;
    const { name, nickname, type } = values;

    return Boolean(!name) && Boolean(!nickname) && Boolean(!type);
  };

  const triggerValidations = useCallback(() => {
    // if form is dirty and not empty, call submitForm() to validate the fields and trigger form inline errors
    // we "unset" the dirty by calling form.resetForm in the section's save handlers
    if (achForm.dirty && !isAchEmpty()) {
      achForm.submitForm();
    }
    if (wireForm.dirty && !isWireEmpty()) {
      wireForm.submitForm();
    }
    if (profileForm.dirty && !isProfileEmpty()) {
      profileForm.submitForm();
    }

    // in add drawer, trigger profile inline errors if profile section is empty
    if (isAddDrawer && !profileForm.dirty) {
      profileForm.submitForm();
    }

    // we want to create a mapper so we know which sections are valid && not have been saved
    const updatedValidNotSavedSections = {
      profile: profileForm.dirty && profileValid,
      wire: wireForm.dirty && wireValid,
      ach: achForm.dirty && achValid,
    };
    setValidNotSavedSections(updatedValidNotSavedSections);

    const isProfileValid = isProfileEmpty() ? true : profileValid;
    const isAchValid = isAchEmpty() ? true : achValid;
    const isWireValid = isWireEmpty() ? true : wireValid;

    const isDrawerValid =
      isProfileValid &&
      isAchValid &&
      isWireValid &&
      // if user opens add drawer, only allow user to toggle profile section if they have saved profile section at least once
      (isAddDrawer ? isProfileSaved : true) &&
      // if one section has not been saved
      Object.values(updatedValidNotSavedSections).every(
        (notSavedSection) => !notSavedSection,
      );

    return {
      isValid: isDrawerValid,
    };
  }, [achForm, wireForm, profileForm]);

  const handleSaveProfile = () => {
    setIsProfileSaved(true);
  };

  return (
    <DrawerContext.Provider
      value={{
        triggerValidations,
        handleSaveProfile,
        validNotSavedSections,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
