import { Row } from "@narmi/design_system";
import styles from "./RecipientDrawer.module.scss";

interface SectionHeaderProps {
  title: string;
  isOpen: boolean;
  checkCondition?: boolean;
}

const SectionHeader = ({
  title,
  isOpen,
  checkCondition = false,
}: SectionHeaderProps) => (
  <Row alignItems="center" className={styles.drawerRow}>
    <Row.Item>
      <h4 className="fontFamily--body">{title}</h4>
      {!isOpen && checkCondition ? <span className="narmi-icon-check" /> : null}
    </Row.Item>
    <Row.Item shrink>
      <span
        className={isOpen ? "narmi-icon-chevron-up" : "narmi-icon-chevron-down"}
      />
    </Row.Item>
  </Row>
);

export default SectionHeader;
