import { createSlice } from "@reduxjs/toolkit";

import { cardLimitsEntityAdapter } from "./adapter";

const cardLimitsSlice = createSlice({
  name: "cardLimits",
  initialState: cardLimitsEntityAdapter.getInitialState(),
  reducers: {
    upsertOne: cardLimitsEntityAdapter.upsertOne,
  },
});

export const { actions } = cardLimitsSlice;

export default {
  cardLimits: cardLimitsSlice.reducer,
};
