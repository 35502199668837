import type { RecipientProfileType } from "./forms";
import type { NetworkPayload } from "../../../composites";

export const API = {
  fetchRecipients: (): NetworkPayload => {
    return {
      url: "recipients/",
      options: {
        method: "GET",
      },
    };
  },

  deleteRecipient: (recipientId: API.RecipientId): NetworkPayload => {
    return {
      url: `recipients/${recipientId}/`,
      options: {
        method: "DELETE",
        isJsonResponse: false,
      },
    };
  },

  createOrUpdateRecipientProfile: (
    { name, nickname, type }: RecipientProfileType,
    recipientId?: API.RecipientId,
  ): NetworkPayload => {
    return {
      url: `recipients/${recipientId ? `${recipientId}/` : ""}`,
      options: {
        method: recipientId ? "PUT" : "POST",
        payload: {
          name,
          nickname,
          type,
        },
      },
    };
  },

  createOrUpdateACHDestination: ({
    account_number,
    routing_number,
    account_type,
    account_purpose,
    recipient,
  }: API.CreateUpdateACHDestination.Request): NetworkPayload => {
    return {
      url: `ach_details/`,
      options: {
        method: "POST",
        payload: {
          account_number,
          routing_number,
          account_type,
          account_purpose,
          recipient,
        },
      },
    };
  },

  createWireDestination: ({
    account_number,
    routing_number,
    address,
    recipient,
  }: API.CreateUpdateWireDestination.Request): NetworkPayload => {
    return {
      url: `fedwire_destinations/`,
      options: {
        method: "POST",
        payload: {
          account_number,
          routing_number,
          address,
          recipient,
        },
      },
    };
  },

  updateWireDestination: ({
    account_number,
    routing_number,
    address,
    recipient,
    fedwireId,
  }: API.CreateUpdateWireDestination.Request & {
    fedwireId: API.FedwireDestinationId;
  }): NetworkPayload => {
    return {
      url: `fedwire_destinations/${fedwireId}/`,
      options: {
        method: "PUT",
        payload: {
          account_number,
          routing_number,
          address,
          recipient,
        },
      },
    };
  },
};
