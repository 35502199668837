import { Route, Routes } from "react-router-dom";
import { ComposeProviders, entities, modules } from "byzantine";
import Container from "../../Container";
import ACHPaymentContainer from "./ACHPaymentContainer";
import ReviewScreen from "./ReviewScreen";
import { SudoProvider, SudoRoutes } from "../../SudoContext";
import RequestOTP from "../../SudoContext/RequestOTP";
import SubmitOTP from "../../SudoContext/SubmitOTP";
import PaymentScreen from "./PaymentScreen";

// corresponding to routes in indigo/urls.py
export const ACH_PAYMENT_ROUTE = "ach_payment";
export const ACH_PAYMENT_REVIEW_ROUTE = "review";
export const ACH_PAYMENT_DETAIL_ROUTE = "detail";

export type LimitsForDisplayType = {
  [key: string]: {
    [key: string]: any;
  };
};

// have to bootstrap recipient.bootstrap.Provider so we can call the `useFetchRecipients` hook in `ACHPaymentContainer`
// `useFetchRecipients` is defined in the bootstrap request hooks
// also bootstrap the form provider so we can use formik
const Providers = [
  modules.achPayments.bootstrap.Provider,
  entities.recipients.ACHDestinationForm.Provider,
  entities.recipients.ACHPaymentRecipientForm.Provider,
  modules.achPayments.ACHPaymentForm.Provider,
];

const ACHPaymentRoutes = ({ limits }: { limits: LimitsForDisplayType }) => (
  <ComposeProviders components={Providers}>
    <Container>
      <SudoProvider basename={ACH_PAYMENT_ROUTE}>
        <Routes>
          <Route path="/" element={<ACHPaymentContainer />} />
          <Route
            path={ACH_PAYMENT_DETAIL_ROUTE}
            element={<PaymentScreen limits={limits} />}
          />
          <Route path={ACH_PAYMENT_REVIEW_ROUTE} element={<ReviewScreen />} />
          <Route path={`${SudoRoutes.RequestOTP}`} element={<RequestOTP />} />
          <Route path={`${SudoRoutes.SubmitOTP}`} element={<SubmitOTP />} />
        </Routes>
      </SudoProvider>
    </Container>
  </ComposeProviders>
);

export default ACHPaymentRoutes;
