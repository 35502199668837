import * as Yup from "yup";

import { createForm } from "../../forms";
import { cents } from "../../../utils";

export type ACHPaymentFormType = {
  fromAccount: string;
  amount: Cents;
  secCode: string;
};

const validationSchema = Yup.object().shape({
  fromAccount: Yup.string().required("Required"),
  amount: Yup.number()
    .integer("Please enter a number.")
    .positive("Please enter a positive amount.")
    .required("Required"),
  secCode: Yup.string().required("Required"),
});

const initialValues: ACHPaymentFormType = {
  fromAccount: "",
  amount: cents(0),
  secCode: "",
};

export const transformACHPaymentFormFieldsToApiFields = ({
  amount,
  fromAccount: institution_account,
  achCompany: ach_company,
  secCode: std_ent_cls_code,
  entryDesc: entry_desc,
  recipient,
}: ACHPaymentFormType & {
  recipient: API.RecipientId;
  entryDesc: API.CreateACHPayment.Request["entry_desc"];
  achCompany: API.CreateACHPayment.Request["ach_company"];
}) => {
  return {
    amount,
    institution_account,
    ach_company,
    std_ent_cls_code,
    entry_desc,
    recipient,
  } as API.CreateACHPayment.Request;
};

export const ACHPaymentForm = createForm({
  initialValues,
  validationSchema,
});
