import { useCallback, useMemo, useEffect } from "react";

import { useSelector } from "react-redux";

import { useObjectMemo } from "../../../hooks";
import { selectCardLimitsByCardId } from "../../entities/cardLimitIncrease/selectors";

import {
  cardLimitIncreaseForm,
  type CardLimitIncreaseType,
} from "./cardLimitIncrease.form";
import { createCardLimitValidationSchema } from "./validationSchema";

import type { CARD_LIMIT_TYPE } from "../../entities/cardLimitIncrease";
import type { InstitutionFeatures } from "../../../../types";
import type { ReactLocalization } from "@fluent/react";

export const useCardLimitIncreaseValidationSchema = (
  cardId: API.CardId,
  l10n: ReactLocalization,
  card_max_request_limits: InstitutionFeatures["card_max_request_limits"],
) => {
  const limits = useSelector(selectCardLimitsByCardId(cardId));

  const validationSchema = useMemo(() => {
    if (!limits) return null;
    return createCardLimitValidationSchema(
      l10n,
      card_max_request_limits,
      limits,
    );
  }, [limits, card_max_request_limits, l10n]);

  return validationSchema;
};

export const useCardLimitIncreaseInitialValues = (
  cardId: API.CardId,
  limitsEnabled: CARD_LIMIT_TYPE[],
) => {
  const limits = useSelector(selectCardLimitsByCardId(cardId));

  const initialValues = useMemo<CardLimitIncreaseType | null>(() => {
    if (!limits) {
      return null;
    }
    const atmLimit = limits.atm_limit ?? null;
    const posLimit = limits.pos_limit ?? null;

    return {
      atmLimit,
      posLimit,
      limitsEnabled,
    };
  }, [limits, limitsEnabled]);

  return initialValues;
};

export const useCardLimitIncreaseForm = (
  cardId: API.CardId,
  l10n: ReactLocalization,
  card_max_request_limits: InstitutionFeatures["card_max_request_limits"],
) => {
  const form = cardLimitIncreaseForm.useForm();
  const { submitForm, values, setValidationSchema } = form;

  const validationSchema = useCardLimitIncreaseValidationSchema(
    cardId,
    l10n,
    card_max_request_limits,
  );

  useEffect(() => {
    if (validationSchema) {
      setValidationSchema(validationSchema);
    }
  }, [validationSchema, setValidationSchema]);

  const onValidate = useCallback(async () => {
    const result = await submitForm();
    return result.success;
  }, [submitForm]);

  return useObjectMemo({
    form,
    values,
    onValidate,
  });
};

export const hasLimitIncreaseFeature = (
  limitsEnabled: CARD_LIMIT_TYPE[],
  card: API.Card,
) =>
  limitsEnabled.length > 0 &&
  card.state === "normal" &&
  card.card_type === "debit";
