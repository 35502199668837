/* eslint-disable camelcase */

import { useState, useEffect, useMemo } from "react";
import { useLocalization } from "@fluent/react";
import { ComposeProviders, entities } from "byzantine";
import { Drawer, useNotificationContext, catcat as cc } from "cerulean";
import type { Features } from "byzantine/src/Feature";
import { ResponsiveFlex } from "@narmi/design_system";
import DrawerLayout from "../../../DrawerLayout";
import { useUserFeatures } from "../../../contexts/UserFeaturesContext";
import styles from "./RecipientDrawer.module.scss";
import ProfileSection from "./ProfileSection";
import ACHDestinationSection from "./ACHDestinationSection";
import WireDestinationSection from "./WireDestinationSection";
import { DrawerValidationsProvider } from "./DrawerValidationsProvider";

type SectionNameProps = "profile" | "ach" | "wire";
type SectionNameStateType = SectionNameProps | "";
type SectionNamesMapperType = {
  [key in SectionNameProps]: SectionNameProps;
};
type DrawerTypeProps = "add" | "edit";
type DrawerTypesMapperType = {
  [key in DrawerTypeProps]: DrawerTypeProps;
};

export type RecipientDrawerProps = {
  isOpen: boolean;
  fromACHFlow?: boolean;
  handleClose: (recipientId?: API.RecipientId) => void;
  recipientId?: API.RecipientId;
  drawerType: DrawerTypeProps;
};

export type SectionProps = {
  title?: string;
  isOpen: boolean;
  // referring to when clicking "save" button or when clicking header to close section
  onClose: () => void;
  onOpen: () => void;
  // referring to when clicking on "save" button
  onSave: (recipientId?: API.RecipientId) => void;
  recipientId?: API.RecipientId;
};
export interface WireSectionProps extends SectionProps {
  savedACHDestination: boolean;
}

const SECTION_NAMES: SectionNamesMapperType = {
  profile: "profile",
  ach: "ach",
  wire: "wire",
};

export const DRAWER_TYPES: DrawerTypesMapperType = {
  add: "add",
  edit: "edit",
};

// Add or Edit Recipient Drawer
const RecipientDrawer = ({
  handleClose,
  isOpen,
  fromACHFlow,
  drawerType,
  recipientId,
}: RecipientDrawerProps) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();
  const features = useUserFeatures() as Features;
  const isEditDrawer = drawerType === DRAWER_TYPES.edit;
  const isAddDrawer = drawerType === DRAWER_TYPES.add;
  // in add drawer, always default open to profile section
  const [enabledSection, setEnabledSection] = useState<SectionNameStateType>(
    isAddDrawer ? SECTION_NAMES.profile : "",
  );
  // in add drawer, done button is default disabled
  const [doneButtonEnabled, setDoneButtonEnabled] =
    useState<boolean>(isEditDrawer);
  const [savedACHDestination, setSavedACHDestination] =
    useState<boolean>(false);
  const [savedWireDestination, setSavedWireDestination] =
    useState<boolean>(false);
  const [addedRecipientInformation, setAddedRecipientInformation] =
    useState<boolean>(false);
  const [currentRecipientId, setCurrentRecipientId] = useState(recipientId);

  const enableSection = (section: SectionNameStateType) => {
    setEnabledSection(section);
  };

  const enableAllSections = () => {
    setEnabledSection("");
  };

  // handles toggling section logic
  // if user saves profile section in add drawer from ach flow, only open ach section
  // if user toggles any other section, enable all sections
  const handleClickSave = () => {
    setAddedRecipientInformation(true);

    if (enabledSection === SECTION_NAMES.ach) {
      setSavedACHDestination(true);
    } else if (enabledSection === SECTION_NAMES.wire) {
      setSavedWireDestination(true);
    } else if (enabledSection === SECTION_NAMES.profile) {
      if (isAddDrawer && fromACHFlow) {
        enableSection(SECTION_NAMES.ach);
        return;
      }
    }

    enableAllSections();
  };

  const handleSaveProfileSection = (
    newlyCreatedRecipientId?: API.RecipientId,
  ) => {
    setCurrentRecipientId(newlyCreatedRecipientId);
    handleClickSave();
  };

  useEffect(() => {
    if (enabledSection) {
      setDoneButtonEnabled(false);
    } else if (!enabledSection) {
      if (isEditDrawer) {
        setDoneButtonEnabled(true);
      } else if (isAddDrawer && fromACHFlow && savedACHDestination) {
        setDoneButtonEnabled(true);
      } else if (
        isAddDrawer &&
        !fromACHFlow &&
        (savedACHDestination || savedWireDestination)
      ) {
        setDoneButtonEnabled(true);
      }
    }
  }, [enabledSection]);

  useEffect(() => {
    // every time drawer is opened, reset the recipientId
    if (isOpen) {
      setCurrentRecipientId(recipientId);
    }
  }, [isOpen]);

  const resetDrawer = () => {
    // reset to state defaults
    enableSection(isAddDrawer ? SECTION_NAMES.profile : "");
    setDoneButtonEnabled(isEditDrawer);
    setSavedACHDestination(false);
    setSavedWireDestination(false);
    setAddedRecipientInformation(false);
  };

  const handleCloseDrawer = () => {
    resetDrawer();
    handleClose(currentRecipientId);

    if (addedRecipientInformation) {
      sendNotification({
        type: "success",
        text: isAddDrawer
          ? "Recipient saved."
          : "Recipient information updated.",
      });
    }
  };

  const getWireSectionHeader = useMemo(() => {
    if (fromACHFlow && !savedWireDestination) {
      return "Wire (optional)";
    }
    return "Wire";
  }, [fromACHFlow, savedWireDestination]);

  const Providers = [
    entities.recipients.RecipientProfileForm.Provider,
    entities.recipients.ACHDestinationForm.Provider,
    entities.recipients.WireDestinationForm.Provider,
  ];

  return (
    <ComposeProviders components={Providers}>
      <Drawer
        depth="633px"
        isOpen={isOpen}
        onUserDismiss={handleCloseDrawer}
        showControls={false}
        paddingSize="none"
      >
        <DrawerValidationsProvider
          drawerType={drawerType}
          recipientId={recipientId}
        >
          <DrawerLayout
            onSave={handleCloseDrawer}
            onCancel={handleCloseDrawer}
            saveLabel={l10n.getString("recipient-drawer-done-button")}
            isSaveDisabled={!doneButtonEnabled}
          >
            <div className={styles.drawerContainer}>
              <ResponsiveFlex gapSize="s">
                <h2>
                  {l10n.getString(
                    isAddDrawer
                      ? "add-recipient-drawer-title"
                      : "edit-recipient-drawer-title",
                  )}
                </h2>
                <p>
                  {l10n.getString(
                    isAddDrawer
                      ? "add-recipient-drawer-subtitle"
                      : "edit-recipient-drawer-subtitle",
                  )}
                </p>
                <ProfileSection
                  isOpen={enabledSection === SECTION_NAMES.profile}
                  onClose={enableAllSections}
                  onOpen={() => enableSection(SECTION_NAMES.profile)}
                  onSave={handleSaveProfileSection}
                  recipientId={currentRecipientId}
                />
                <ACHDestinationSection
                  isOpen={enabledSection === SECTION_NAMES.ach}
                  onClose={enableAllSections}
                  onOpen={() => enableSection(SECTION_NAMES.ach)}
                  onSave={handleClickSave}
                  recipientId={currentRecipientId}
                />

                {/* launching add drawer from ach flow nestles wire section under "additional details" */}
                {features?.beta_wire_recipients_in_ach_payments &&
                fromACHFlow &&
                isAddDrawer ? (
                    <div className={cc(styles.additionalDetailsSection)}>
                      <h2>
                        {l10n.getString(
                          "add-recipient-drawer-additional-details-title",
                          null,
                          "Additional details",
                        )}
                      </h2>
                      <p>
                        {l10n.getString(
                          "add-recipient-drawer-additional-details-subtitle",
                          null,
                          "Enter additional details so this recipient can be used for other transfer types.",
                        )}
                      </p>
                    </div>
                  ) : null}

                {features?.beta_wire_recipients_in_ach_payments ? (
                  <WireDestinationSection
                    title={getWireSectionHeader}
                    isOpen={enabledSection === SECTION_NAMES.wire}
                    onClose={enableAllSections}
                    onOpen={() => enableSection(SECTION_NAMES.wire)}
                    savedACHDestination={savedACHDestination}
                    onSave={handleClickSave}
                    recipientId={currentRecipientId}
                  />
                ) : null}
              </ResponsiveFlex>
            </div>
          </DrawerLayout>
        </DrawerValidationsProvider>
      </Drawer>
    </ComposeProviders>
  );
};

export default RecipientDrawer;
