import { createSelector } from "reselect";

import { cardLimitsEntityAdapter } from "./adapter";

import type { CardLimitEntity } from "./types";
import type { RootState } from "../types";

export const getCardLimitsState = (state: RootState) => state.cardLimits;

const { selectById: selectByCardId } =
  cardLimitsEntityAdapter.getSelectors(getCardLimitsState);

export const selectCardLimitsByCardId = (id: API.CardId) =>
  createSelector(
    (state: RootState): CardLimitEntity | undefined =>
      selectByCardId(state, id),
    (cardLimitEntity) => cardLimitEntity?.limits,
  );
