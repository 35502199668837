// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".background--qy5Mr{background-color:var(--bgColor-smokeGrey)}.background--qy5Mr .container--ga0av{padding-top:0 !important;padding-bottom:0 !important}.wrapper--fg6jz{display:flex;flex-direction:row;align-items:flex-start;margin:0;padding:10px}.text--WcjfW{margin-left:var(--space-xs);font-size:12.8px;font-style:Italic;font-weight:var(--font-weight-normal)}", "",{"version":3,"sources":["webpack://./components/FdicBanner/FdicBanner.module.scss"],"names":[],"mappings":"AAAA,mBACE,yCAAA,CAEA,qCACE,wBAAA,CACA,2BAAA,CAIJ,gBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,YAAA,CAGF,aACE,2BAAA,CACA,gBAAA,CACA,iBAAA,CACA,qCAAA","sourcesContent":[".background {\n  background-color: var(--bgColor-smokeGrey);\n\n  .container {\n    padding-top: 0 !important;\n    padding-bottom: 0 !important;\n  }\n}\n\n.wrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  margin: 0;\n  padding: 10px;\n}\n\n.text {\n  margin-left: var(--space-xs);\n  font-size: 12.8px;\n  font-style: Italic;\n  font-weight: var(--font-weight-normal);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": "background--qy5Mr",
	"container": "container--ga0av",
	"wrapper": "wrapper--fg6jz",
	"text": "text--WcjfW"
};
export default ___CSS_LOADER_EXPORT___;
