import type { ScheduledPayload } from "../../../../TransferSchedule";
import type { InternalLoanPaymentPayload } from "../../../../types/models/v1";
import type { NetworkPayload } from "../../../composites/request/request.types";

const internalLoanPaymentsUrl = "internal_loan_payments/";
const scheduledPaymentsUrl = "transfers/scheduled";

export const API = {
  makeInternalPrincipalPayment: (
    payload: InternalLoanPaymentPayload<"principal">,
  ): NetworkPayload => {
    return {
      url: internalLoanPaymentsUrl,
      options: {
        method: "POST",
        payload,
      },
    };
  },

  makeInternalDefaultPayment: (
    payload: InternalLoanPaymentPayload<"default">,
  ): NetworkPayload => {
    return {
      url: internalLoanPaymentsUrl,
      options: {
        method: "POST",
        payload,
      },
    };
  },

  makeInternalPayment: (
    payload: InternalLoanPaymentPayload<"default" | "principal">,
  ): NetworkPayload => {
    return {
      url: internalLoanPaymentsUrl,
      options: {
        method: "POST",
        payload,
      },
    };
  },

  makeScheduledTransfer: (payload: ScheduledPayload): NetworkPayload => {
    return { url: scheduledPaymentsUrl, options: { method: "POST", payload } };
  },
};
