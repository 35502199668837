import React, { memo, useMemo } from "react";
import { Tag } from "@narmi/design_system";
import { ContentCard } from "cerulean";
import { useTranslation } from "react-i18next";
import { useLocalization } from "@fluent/react";
import { useFeature } from "byzantine";

const SecurityAlerts = () => {
  const { l10n } = useLocalization();
  const { t } = useTranslation();
  const { featureEnabled } = useFeature();
  const securityAlerts: string[][] = useMemo(() => {
    const alerts = [
      [
        t(l10n.getString("security-alert-label-password-reset", {}, "Reset password")),
        t(l10n.getString("security-alert-password-reset", {}, "when I reset my password.")),
      ],
      [
        t(l10n.getString("security-alert-label-password-change", {}, "Change password")),
        t(l10n.getString("security-alert-password-change", {}, "when I change my password.")),
      ],
      [
        t(l10n.getString("security-alert-label-email-change", {}, "Change email")),
        t(l10n.getString("security-alert-email-change", {}, "when I change my email.")),
      ],
      [
        t(l10n.getString("security-alert-label-phone-change", {}, "Change phone number")),
        t(l10n.getString("security-alert-phone-change", {}, "when I change my phone number.")),
      ],
      [
        t(l10n.getString("security-alert-label-address-change", {}, "Change address")),
        t(l10n.getString("security-alert-address-change", {}, "when I change my address.")),
      ],
      [
        t(l10n.getString("security-alert-label-staff-create-2fa", {}, "2FA backup codes are generated")),
        t(l10n.getString("security-alert-staff-create-2fa", {}, "when staff generates backup two-factor authentication codes for me.")),
      ],
      [
        t(l10n.getString("security-alert-label-add-remove-2fa", {}, "Update 2FA devices")),
        t(l10n.getString("security-alert-add-remove-2fa", {}, "when I add or remove a two-factor authentication device.")),
      ],
      [
        t(l10n.getString("security-alert-label-add-external-account", {}, "Add external account")),
        t(l10n.getString("security-alert-add-external-account", {}, "when I successfully add an external account.")),
      ],
      [
        t(l10n.getString("security-alert-label-enroll-banking", {}, "Re-enroll")),
        t(l10n.getString("security-alert-enroll-banking", {}, "when someone attempts to enroll in digital banking with my account information.")),
      ],
    ];

    if (featureEnabled("olb_card_travel_notes")) {
      alerts.push([
        t(l10n.getString(
          "security-alert-label-card-travel-notes",
          {},
          "Travel notes"
        )),
        t(l10n.getString(
          "security-alert-card-travel-notes",
          {},
          "When I create a new travel note for a linked card"
        ))
      ]);
    }

    if (
      featureEnabled("olb_enable_card_pos_limit") ||
      featureEnabled("olb_enable_card_atm_limit")
    ) {
      alerts.push([
        t(l10n.getString(
          "security-alert-label-card-limit-increase",
          {},
          "Limit increase"
        )),
        t(l10n.getString(
          "security-alert-card-limit-increase",
          {},
          "When I request a limit increase for a linked card"
        )),
      ]);
    }

    return alerts;
  }, [t, l10n, featureEnabled]);

  return (
    <div className="security-card">
      <ContentCard>
        <div className="alerts-title-container">
          <h3 className="semibold">Security alerts</h3>
        </div>
        <div className="margin--y--m">
          <p>
            These are security-related email notifications and cannot be disabled.
          </p>
        </div>
        {securityAlerts.map(([label, description], index) => (
          <div key={label}>
            <div>
              <Tag label={label} /> {description}
            </div>
            {securityAlerts.length - 1 !== index && ( // don't show horizontal rule if it's the last element
              <hr />
            )}
          </div>
        ))}
      </ContentCard>
    </div>
  );
};

export default memo(SecurityAlerts);
