import OrganizationUser from "byzantine/src/OrganizationUser";
import Role, { ALL, MODEL_STR } from "byzantine/src/Role";
import functions from "byzantine/src/utils";
import { ContextForm, useFormData } from "cerulean";
import { formatNumber } from "@narmi/design_system";
import PropTypes from "prop-types";
import React, { useState } from "react";
import utils from "../../utils";
import BaseBanner from "../BaseBanner";
import AccessLevelSection from "./AccessLevelSection";
import OrgUserActions from "./OrgUserActions";
import OrganizationAccessLevelExtraPermissions from "./OrganizationAccessLevelExtraPermissions";
import UserInfoHeader from "./UserInfoHeader";
import UserInfoInputs from "./UserInfoInputs";

export const anyAccountsSelected = (accounts) =>
  accounts.some((account) => account.selected);

export const bundleExtraPermissions = (data) => {
  const extraPermissions = {};
  OrganizationAccessLevelExtraPermissions.affectedPermissions.forEach(
    (model) => {
      extraPermissions[model] = data[model] ? data[model] : false;
    },
  );
  return extraPermissions;
};

export const validateExtraPermissions = (data) => {
  if (data.accessLevel === "Collaborator") {
    const extraPermissions = bundleExtraPermissions(data);
    const hasExtraPermissions = Object.values(extraPermissions).some(
      (value) => value,
    );
    if (!hasExtraPermissions) {
      return "Must select at least one permission";
    }
    if (
      extraPermissions[MODEL_STR.ACH_TRANSFER] &&
      !anyAccountsSelected(data.externalAccounts) &&
      !!data.externalAccounts.length
    ) {
      return "Must select at least one external account";
    }
  }
  return null;
};

const OrganizationEditUser = (props) => {
  const dualApprovalRequired = JSON.parse(props.dualApprovalRequiredJson);
  const isUsingConsumerBillpay = !!JSON.parse(props.isUsingConsumerBillpayJson);
  const roles = JSON.parse(props.roles).map((role) => new Role(role));
  const isPending =
    props.organizationUser.status === OrganizationUser.STATUS.PENDING;
  const isInactive =
    props.organizationUser.status === OrganizationUser.STATUS.INACTIVE;
  const successMessage = isPending ? "Invite+resent." : "Changes+saved.";
  const [permissionsError, setPermissionsError] = useState(null);

  const defaultDailyACHLimit = props.features.limits?.ach_push?.[1]
    ? formatNumber(props.features.limits?.ach_push?.[1])
    : "";
  const defaultDailyWireLimit = props.features.limits?.wire?.[1]
    ? formatNumber(props.features.limits?.wire?.[1])
    : "";
  const defaultDailyACHPaymentLimit = props.features.limits
    ?.unverified_ach_push?.[1]
    ? formatNumber(props.features.limits?.unverified_ach_push?.[1])
    : "";

  let submitButtonLabel;
  if (isPending) {
    submitButtonLabel = "Resend invite";
  } else if (isInactive) {
    submitButtonLabel = "Reactivate user";
  } else {
    submitButtonLabel = "Save";
  }

  const initialData = {
    ...props.organizationUser.user,
    phone: functions.phoneUnformatter(props.organizationUser.user.phone),
    accounts: props.organizationUser.role.markSelectedAccounts(props.accounts),
    externalAccounts: props.organizationUser.role.markSelectedAccounts(
      props.externalAccounts,
    ),
    accessLevel: props.organizationUser.role.getAccessLevel(),
    ach_limits: props.organizationUser.role.limits?.ach_push?.[1]
      ? formatNumber(
          props.organizationUser.role.limits?.ach_push?.[1].toString(),
        )
      : defaultDailyACHLimit.toString(),
    wire_limits: props.organizationUser.role.limits?.wire?.[1]
      ? formatNumber(props.organizationUser.role.limits?.wire?.[1].toString())
      : defaultDailyWireLimit.toString(),
    ach_payment_limits: props.organizationUser.role.limits
      ?.unverified_ach_push?.[1]
      ? formatNumber(
          props.organizationUser.role.limits?.unverified_ach_push?.[1].toString(),
        )
      : defaultDailyACHPaymentLimit.toString(),
    extraPermissions: {},
  };

  // this code defaults extra permissions to checked/unchecked
  // based on whether any role permission matches the extra permission's
  // model & operation
  OrganizationAccessLevelExtraPermissions.affectedPermissions.forEach(
    (model) => {
      initialData[model] = Boolean(
        props.organizationUser.role.permissions.find((p) => {
          const matchesModel = p.model_str === model;
          const isWriteOperation = ["*", "write"].includes(p.operation);
          if (
            !matchesModel ||
            (props.organizationUser.role.getAccessLevel() !== "Viewer" &&
              !isWriteOperation)
          )
            return false;
          if (
            p.model_str !== MODEL_STR.EXTERNAL_ACCOUNT &&
            p.model_str !== MODEL_STR.ACCOUNT
          )
            return true;
          // even if a collaborator has an existing permission to use a given external account
          // we still want to skip auto-checking the permission to add external accounts unless
          // the permission is specifically to edit all external accounts
          return p.uuid === ALL;
        }),
      );
    },
  );

  const { onChange, formData } = useFormData(initialData);

  const onSubmit = (callback) => {
    const error = validateExtraPermissions(formData);
    if (error) {
      setPermissionsError(error);
      callback();
      return;
    }
    formData.extraPermissions = bundleExtraPermissions(formData);
    //  email and phone will only be processed in the API if user status is pending
    // if a user's ability to make external transfers or wires has changes, we need to update their limits
    let ach_limits = 0;
    let wire_limits = 0;
    let ach_payment_limits = 0;
    if (formData.extraPermissions[MODEL_STR.ACH_TRANSFER]) {
      ach_limits = utils.parseValueAsFloat(formData.ach_limits);
    }
    if (formData.extraPermissions[MODEL_STR.WIRE_TRANSACTION]) {
      wire_limits = utils.parseValueAsFloat(formData.wire_limits);
    }
    if (formData.extraPermissions[MODEL_STR.ACH_PAYMENT]) {
      ach_payment_limits = utils.parseValueAsFloat(formData.ach_payment_limits);
    }
    const user = new OrganizationUser({
      uuid: props.organizationUser.uuid,
      user: {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        phone: formData.phone,
      },
      status: props.organizationUser.status,
      role: Role.generateFromAccessLevel(
        formData.accessLevel,
        formData.accounts,
        formData.externalAccounts,
        formData.extraPermissions,
        roles,
        { ach_limits, wire_limits, ach_payment_limits },
      ),
      organization_uuid: props.orgId,
    });
    user
      .updateAttrs()
      .then((response) => {
        let infoToast = "";
        if (
          response.organization_user?.status ===
            OrganizationUser.STATUS.PENDING &&
          response?.organization_user?.demo_invite_url
        ) {
          infoToast = `&info=Demo+only:+click+[here](${response?.organization_user?.demo_invite_url})+to+access+the+invite+link.`;
        }
        window.location.assign(
          `/manage_users?success=${successMessage}${infoToast}`,
        );
      })
      .catch(callback);
  };

  return (
    <div style={{ minHeight: "800px" }}>
      <BaseBanner
        bannerName="Edit permissions"
        goBack={() => window.location.assign("/manage_users")}
      />
      <ContextForm data={formData} onChange={onChange}>
        <div className="section-box">
          <UserInfoHeader
            organizationUser={props.organizationUser}
            orgId={props.orgId}
            showDeactivateButton={!isInactive}
          />
          <UserInfoInputs isPending={isPending} isEditing={true} />
        </div>
        <AccessLevelSection
          formData={formData}
          features={props.features}
          isEditing={true}
          roles={roles}
          organizationUsers={props.organizationUsers}
          errorMessage={permissionsError}
          onChange={onChange}
          dualApprovalRequired={dualApprovalRequired}
          isUsingConsumerBillpay={isUsingConsumerBillpay}
        />
        <OrgUserActions
          onSubmit={onSubmit}
          submitButtonLabel={submitButtonLabel}
        />
      </ContextForm>
    </div>
  );
};
OrganizationEditUser.propTypes = {
  accounts: PropTypes.array,
  externalAccounts: PropTypes.array,
  organizationUser: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  roles: PropTypes.string.isRequired,
  features: PropTypes.object,
  organizationUsers: PropTypes.array.isRequired,
  dualApprovalRequiredJson: PropTypes.string,
  isUsingConsumerBillpayJson: PropTypes.string,
};
OrganizationEditUser.defaultProps = {
  accounts: [],
  externalAccounts: [],
};

export default OrganizationEditUser;
