export const DEBUG_STYLES = `
* {
  text-transform: unset !important;
  font-variant: unset !important;
  font-variant-caps: unset !important;
}
*::first-letter {
  text-transform: unset !important;
}
body {
  padding: 16px;
}
body::after {
  content: "\u2591\u2592\u2593\u2588   translations debug mode | Use language selector to exit   \u2588\u2593\u2592\u2591";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  background: var(--color-azul);
  text-align: center;
  padding: 4px;
  font-family: monospace;
  font-size: 12px;
  text-transform: uppercase !important;
}
body::before {
  content: "";
  position: fixed;
  inset: 0;
  border: 22px solid var(--color-azul);
  border-top-width: 0;
}
`;
