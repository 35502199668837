import { ContentCard } from "cerulean";
import styles from "./PreviewCard.module.scss";

type PreviewCardType = {
  header?: string;
  subtleHeader?: string;
  subHeader?: string;
  iconName?: string;
  iconContent?: string | React.ReactElement;
  actionButton?: React.ReactElement;
};

const PreviewCard = ({
  header,
  subtleHeader,
  subHeader,
  iconName,
  iconContent,
  actionButton,
}: PreviewCardType) => (
  <ContentCard kind="bordered">
    <div className={styles.previewCardContainer}>
      <div className={styles.topContainer}>
        <div className={styles.headings}>
          {header ? <span className={styles.header}>{header}</span> : null}
          {subtleHeader ? (
            <span className={styles.subtleHeader}>{subtleHeader}</span>
          ) : null}
          {subHeader ? (
            <div className={styles.subHeader}>{subHeader}</div>
          ) : null}
        </div>
        <div className={styles.actionButton}>{actionButton || null}</div>
      </div>
      <div className={styles.bottomContainer}>
        {iconName ? (
          <div className={styles.logo}>
            <span className={iconName} />
          </div>
        ) : null}
        {iconContent || null}
      </div>
    </div>
  </ContentCard>
);

export default PreviewCard;
