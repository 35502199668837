import * as Yup from "yup";

import { createForm } from "../../../forms";
import finance from "../../../../../finance";

type AddressType = {
  streetAddress: string;
  streetAddress2?: string;
  city: string;
  regionCode: string;
  postalCode: string;
};

export type WireDestinationFormType = {
  accountNumber: string;
  routingNumber: string;
  address: AddressType;
  "address.streetAddress"?: string;
  "address.streetAddress2"?: string;
  "address.city"?: string;
  "address.regionCode"?: string;
  "address.postalCode"?: string;
};

const validationSchema = Yup.object().shape({
  accountNumber: Yup.string().required("Required").max(34),
  routingNumber: Yup.string()
    .required("Required")
    .test(
      "length",
      "Please enter a valid 9-digit routing number.",
      (val) => val.length === 9,
    )
    .test("valid", "Please enter a valid 9-digit routing number.", (val) => {
      const isValid = finance.abaRoutingNumberIsValid(val);
      return isValid;
    }),
  address: Yup.object().shape({
    streetAddress: Yup.string().required("Required").max(35),
    streetAddress2: Yup.string().max(35),
    city: Yup.string().required("Required").max(17),
    regionCode: Yup.string().required("Required"),
    postalCode: Yup.string().required("Required").max(10),
  }),
});

export const wireDestinationInitialValues: WireDestinationFormType = {
  accountNumber: "",
  routingNumber: "",
  address: {
    streetAddress: "",
    streetAddress2: "",
    city: "",
    regionCode: "",
    postalCode: "",
  },
};

export const transformWireApiFieldsToFormFields = ({
  account_number: accountNumber,
  routing_number: routingNumber,
  address: {
    street_address: streetAddress,
    street_address_2: streetAddress2,
    city,
    region_code: regionCode,
    postal_code: postalCode,
  },
}: API.FedwireDestination) => {
  return {
    accountNumber,
    routingNumber,
    address: {
      streetAddress,
      streetAddress2,
      city,
      regionCode,
      postalCode,
    },
  };
};

export const transformWireFormFieldsToApiFields = ({
  accountNumber: account_number,
  routingNumber: routing_number,
  address: {
    streetAddress: street_address,
    streetAddress2: street_address_2,
    city,
    regionCode: region_code,
    postalCode: postal_code,
  },
  recipientId,
}: WireDestinationFormType & { recipientId: API.RecipientId }) => {
  return {
    account_number,
    routing_number,
    address: {
      street_address,
      street_address_2,
      city,
      region_code,
      postal_code,
      country_code: "US",
    },
    recipient: recipientId,
  } as API.CreateUpdateWireDestination.Request;
};

// define form schema and validations
export const WireDestinationForm = createForm({
  initialValues: wireDestinationInitialValues,
  validationSchema,
});
