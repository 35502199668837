/* eslint-disable camelcase */

import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ProgressButtons,
  Tooltip,
  Select,
  RoutingNumberTextInput,
  AccountNumberTextInput,
  Button,
  ResponsiveFlex,
  Row,
} from "cerulean";
import { entities, modules } from "byzantine";
import { Features } from "byzantine/src/Feature";
import { useLocalization } from "@fluent/react";
import * as formAdapters from "../../../src/adapters";
import HeaderWithSteps from "../../HeaderWithSteps";
import { useSudoContext } from "../../SudoContext";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";
import { useInstitutionSettings } from "../../contexts/InstitutionSettingsContext";
import RecipientSelector from "../RecipientSelector";
import RecipientDrawer, {
  DRAWER_TYPES,
} from "./RecipientDrawer/RecipientDrawer";
import {
  ACH_PAYMENT_ROUTE,
  ACH_PAYMENT_DETAIL_ROUTE,
} from "./ACHPaymentRoutes";

const TOTAL_STEPS = 3;

const RecipientSection = () => {
  const { l10n } = useLocalization();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const achPaymentForm = modules.achPayments.ACHPaymentForm.useForm();
  const recipientForm = entities.recipients.ACHPaymentRecipientForm.useForm();
  const hasManageRecipientPermission =
    modules.achPayments.useManageRecipientPermission();
  // if user doesn't have manage recipient permissions, just show them recipients they can only send ach payments to
  const recipients = modules.achPayments.usePermissionedRecipients();
  const {
    values: { selectedRecipientId },
  } = recipientForm;
  const { onChange, ...restOfFieldProps } = formAdapters.fieldWithOnChange(
    recipientForm,
    "selectedRecipientId",
  );

  const handleOnChange = (recipientId: API.RecipientId) => {
    // reset the ach payment form on the following screen if recipient changes
    if (achPaymentForm.dirty && recipientId !== selectedRecipientId) {
      achPaymentForm.resetForm();
    }

    onChange(recipientId);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleCloseDrawer = (recipientId?: API.RecipientId) => {
    if (recipientId) {
      handleOnChange(recipientId);
    }
    toggleDrawer();
  };

  return (
    <>
      <ResponsiveFlex gapSize="m">
        <h4 className="fontSize--m nds-sans">
          {l10n.getString("ach-payment-recipient-title")}
        </h4>
        <div className="recipient-screen__recipient-selector">
          <RecipientSelector
            recipients={recipients}
            field="recipientId"
            label="To"
            onAddRecipientClick={() => {
              toggleDrawer();
            }}
            onChange={handleOnChange}
            displayBottomAction={hasManageRecipientPermission}
            {...restOfFieldProps}
          />
        </div>
      </ResponsiveFlex>
      <RecipientDrawer
        handleClose={handleCloseDrawer}
        isOpen={isDrawerOpen}
        fromACHFlow={true}
        drawerType={DRAWER_TYPES.add}
      />
    </>
  );
};

const BankDetailsSection = ({
  selectedRecipient,
}: {
  selectedRecipient?: API.Recipient;
}) => {
  const { l10n } = useLocalization();
  const features = useUserFeatures() as Features;
  const accountTypes = entities.recipients.useAccountTypeOptions();
  const { form } = entities.recipients.useACHDestinationForm(
    selectedRecipient?.id,
  );
  const { fedwire_destination } = selectedRecipient as API.Recipient;

  const handleClickUseWireDetails = () => {
    form.setFieldValue("accountNumber", fedwire_destination?.account_number);
    form.setFieldValue("routingNumber", fedwire_destination?.routing_number);
  };

  return (
    <ResponsiveFlex gapSize="m">
      <Row>
        <Row.Item>
          <h4 className="fontSize--m nds-sans">
            {l10n.getString("ach-payment-bank-title")}
          </h4>
        </Row.Item>
        {features?.beta_wire_recipients_in_ach_payments &&
        fedwire_destination?.account_number ? (
            <Row.Item shrink>
              <Button
                label={l10n.getString("ach-payment-copy-wire-cta")}
                kind="plain"
                size="s"
                onClick={handleClickUseWireDetails}
              />
            </Row.Item>
          ) : null}
      </Row>

      <div className="message-content info">
        <span className="narmi-icon-info margin--right--m" />
        {l10n.getString("ach-payment-ach-details-banner")}
      </div>

      <AccountNumberTextInput
        label={l10n.getString("labelAccountNumber", null, "Account number")}
        {...formAdapters.fieldWithOnChange(form, "accountNumber")}
      />

      <RoutingNumberTextInput
        label={l10n.getString("label-routing", null, "Routing number")}
        {...formAdapters.fieldWithOnChange(form, "routingNumber")}
      />

      <Select
        label={l10n.getString("ach-payment-account-type", null, "Account type")}
        {...formAdapters.select(form, "accountType")}
      >
        {accountTypes.map((option, index) => (
          <Select.Item key={`account_type_${index}`} value={option}>
            {option}
          </Select.Item>
        ))}
      </Select>
    </ResponsiveFlex>
  );
};

export const getTotalSteps = () => {
  const { totalSteps: totalStepsWithSudo } = useSudoContext();
  const { sudo_mode_required_for_ach_payments: sudoModeRequired } =
    useInstitutionSettings();
  return sudoModeRequired ? totalStepsWithSudo : TOTAL_STEPS;
};

const RecipientScreen = () => {
  const { l10n } = useLocalization();
  const navigate = useNavigate();
  const { useSetInitialSteps } = useSudoContext();
  const [showBankDetailsSection, setShowBankDetailsSection] = useState(false);
  const { form: paymentDetailsForm } =
    entities.recipients.useACHDestinationForm();
  const selectedRecipient = entities.recipients.useSelectedRecipient();

  const onSubmitSuccessful = useCallback(() => {
    navigate(`/${ACH_PAYMENT_ROUTE}/${ACH_PAYMENT_DETAIL_ROUTE}`);
  }, [navigate]);

  const handleSubmit = entities.recipients.useHandleRecipientPageSubmit({
    onSubmitSuccessful,
  });

  useEffect(() => {
    // Logic specific to the Web recipient screen
    // only show bank details section if selected recipient is a wire recipient or has no payment info saved

    const showSection =
      Boolean(selectedRecipient) &&
      (Boolean(entities.isWireRecipient(selectedRecipient)) ||
        Boolean(entities.hasNoPaymentDetails(selectedRecipient)));

    setShowBankDetailsSection(showSection);

    // Reset the payment details if current recipient is not a wire recipient
    if (
      !showSection &&
      !entities.isWireRecipient(selectedRecipient) &&
      paymentDetailsForm.dirty
    ) {
      paymentDetailsForm.handleReset(paymentDetailsForm.initialValues);
    }
  }, [selectedRecipient]);

  useSetInitialSteps(TOTAL_STEPS);

  return (
    <ResponsiveFlex gapSize="xl">
      <HeaderWithSteps
        headerText={l10n.getString("ach-payment-payment-title")}
        step={1}
        totalSteps={getTotalSteps()}
        icon={
          <Tooltip text={l10n.getString("ach-payment-payment-title-tooltip")}>
            <i
              className="narmi-icon-info"
              style={{
                fontSize: "var(--font-size-s)",
              }}
            />
          </Tooltip>
        }
      />

      <RecipientSection />

      {showBankDetailsSection ? (
        <BankDetailsSection selectedRecipient={selectedRecipient} />
      ) : null}

      <ProgressButtons
        backLabel={l10n.getString("button-cancel")}
        nextLabel={l10n.getString("button-next")}
        onBack={() => {
          navigate("/transfers");
        }}
        onNext={handleSubmit}
      />
    </ResponsiveFlex>
  );
};

export default RecipientScreen;
