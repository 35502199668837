// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".drawerLayout--xCBXd{height:100%;display:flex;flex-direction:column}.content--AbWrF{overflow-y:auto;overflow-x:hidden;flex-grow:1;padding:0 var(--space-l) var(--space-l)}@media only screen and (min-width: 768px){.content--AbWrF{padding:var(--space-xl)}}", "",{"version":3,"sources":["webpack://./components/DrawerLayout/DrawerLayout.module.scss","webpack://./../scss/_mixins.scss"],"names":[],"mappings":"AAEA,qBACE,WAAA,CACA,YAAA,CACA,qBAAA,CAGF,gBACE,eAAA,CACA,iBAAA,CACA,WAAA,CACA,uCAAA,CCGA,0CDPF,gBAOI,uBAAA,CAAA","sourcesContent":["@import \"../../../scss/mixins\";\n\n.drawerLayout {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.content {\n  overflow-y: auto;\n  overflow-x: hidden;\n  flex-grow: 1;\n  padding: 0 var(--space-l) var(--space-l);\n\n  @include minViewport(\"m\") {\n    padding: var(--space-xl);\n  }\n}\n","@use \"sass:map\";\n@import \"./vars\";\n\n/**\n* Wraps content in a media query that targets the\n* given breakpoint size _and larger_.\n*\n* ```scss\n* @include minViewport(\"m\") {\n*   // styles targeting \"m\" size _and larger_\n* }\n* ```\n*/\n@mixin minViewport($breakpointSize) {\n  $breakpoint: map-get($breakpoints-map, $breakpointSize);\n  @media only screen and (min-width: #{$breakpoint}) {\n    @content;\n  }\n}\n\n/**\n* Wraps content in a media query that targets viewports\n* smaller than the given breakpoint size.\n* (Not inclusive of the given breakpoint)\n*\n* ```scss\n* @include maxViewport(\"m\") {\n*   // styles targeting viewports smaller than \"m\"\n* }\n* ```\n*/\n@mixin maxViewport($breakpointSize) {\n  $breakpoint: map-get($breakpoints-map, $breakpointSize);\n  @media only screen and (max-width: #{$breakpoint - 1px}) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawerLayout": "drawerLayout--xCBXd",
	"content": "content--AbWrF"
};
export default ___CSS_LOADER_EXPORT___;
