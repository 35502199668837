// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--g9idV{margin-bottom:var(--space-l)}.title--yQp3l{margin-right:\"auto\"}.iframeContainer--v13M7{display:flex;justify-content:center}.errorContainer--c_zFu{margin-top:var(--space-l)}.errorContainer--c_zFu span.nds-alert-icon.narmi-icon-info{font-size:var(--font-size-m)}.errorContainer--c_zFu div.nds-alert.padding--all{padding:var(--space-s) !important;font-size:var(--font-size-s)}.errorContainer--c_zFu span.nds-button-label{font-size:var(--font-size-s);font-weight:var(--font-weight-normal);text-decoration:underline;color:var(--font-color-primary)}.errorContainer--c_zFu button.nds-button.nds-button--plain{min-height:auto !important}", "",{"version":3,"sources":["webpack://./components/savvy_money/SavvyMoneyWidget/SavvyMoneyWidget.module.scss"],"names":[],"mappings":"AAAA,kBACE,4BAAA,CAGF,cACE,mBAAA,CAGF,wBACE,YAAA,CACA,sBAAA,CAGF,uBACE,yBAAA,CAEA,2DACE,4BAAA,CAGF,kDACE,iCAAA,CACA,4BAAA,CAGF,6CACE,4BAAA,CACA,qCAAA,CACA,yBAAA,CACA,+BAAA,CAGF,2DACE,0BAAA","sourcesContent":[".container {\n  margin-bottom: var(--space-l);\n}\n\n.title {\n  margin-right: \"auto\";\n}\n\n.iframeContainer {\n  display: flex;\n  justify-content: center;\n}\n\n.errorContainer {\n  margin-top: var(--space-l);\n\n  span:global(.nds-alert-icon.narmi-icon-info) {\n    font-size: var(--font-size-m);\n  }\n\n  div:global(.nds-alert.padding--all) {\n    padding: var(--space-s) !important;\n    font-size: var(--font-size-s);\n  }\n\n  span:global(.nds-button-label) {\n    font-size: var(--font-size-s);\n    font-weight: var(--font-weight-normal);\n    text-decoration: underline;\n    color: var(--font-color-primary);\n  }\n\n  button:global(.nds-button.nds-button--plain) {\n    min-height: auto !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--g9idV",
	"title": "title--yQp3l",
	"iframeContainer": "iframeContainer--v13M7",
	"errorContainer": "errorContainer--c_zFu"
};
export default ___CSS_LOADER_EXPORT___;
