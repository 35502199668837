import { createDeepEqualSelector } from "../utils/createDeepEqualSelector";

import type { RootState } from "../store";

export const selectAllLimits = (state: RootState) => state.limits.data;
export const selectWireLimit = (state: RootState) => state.limits.data.wire;

export const selectLimitType = createDeepEqualSelector(
  [selectAllLimits, (_, type: API.Limits.Type) => type],
  (limits, type) => (limits[type] || null) as API.Limits.Limit | null,
);

const isInterval = (s: string): s is API.Limits.Interval =>
  !Number.isNaN(parseFloat(s));

export const selectLimitsForDisplay = createDeepEqualSelector(
  [selectAllLimits, (_, types: API.Limits.Type[]) => types],
  (allLimits, types) => {
    const allDisplayLimits: API.Limits.DisplayLimits = {};

    types.forEach((type) => {
      const limit = (allLimits[type] || null) as API.Limits.Limit | null;

      if (!limit || !Object.values(limit).some((l) => !!l)) {
        return;
      }
      const displayLimits: API.Limits.DisplayLimit[] = [];

      Object.entries(limit).forEach(([period, limitInfo]) => {
        if (isInterval(period) && limitInfo && typeof limitInfo !== "number") {
          displayLimits.push({
            period,
            limitInfo,
          });
        }
      });

      allDisplayLimits[type] = displayLimits;
    });

    return allDisplayLimits;
  },
);
