export enum CARD_LIMIT_TYPE {
  ATM = "atmLimit",
  POS = "posLimit",
}

export interface CardLimitEntity {
  id: API.CardId;
  limits: API.CardLimits;
}

export type CardLimitPayload = CardLimitEntity;

export type UpdateLimitsPayload = {
  atmLimit?: number;
  posLimit?: number;
};

export type CardLimitsRequestParams = {
  cardId: API.CardId;
  onSuccess?(): void;
  onError?(): void;
  onData?(cardLimits: API.CardLimits): void;
};

export type CardLimitsUpdateParams = {
  card: API.Card;
  onSuccess?(): void;
  onError?(): void;
  onData?(cardLimits: API.CardLimits): void;
};
