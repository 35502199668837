import React, { useState } from "react";
import PropTypes from "prop-types";

import Institution from "byzantine/src/Institution";
import AzulFooter from "../components/AzulFooter";

const FooterContainer = (props) => {
  const [institutionSettings, setInstitutionSettings] = useState({});

  React.useEffect(() => {
    const institution = new Institution();
    institution
      .getSettings()
      .then((settingsResponse) => setInstitutionSettings(settingsResponse));
  }, []);

  return (
    <AzulFooter
      ehlLogoUrl={props.ehlLogoUrl}
      institutionSettings={institutionSettings}
    />
  );
};
FooterContainer.propTypes = {
  ehlLogoUrl: PropTypes.string,
};

export default FooterContainer;
