export default function createLocationChangeEvent() {
  const originalPushState = window.history.pushState.bind(window.history);
  const originalReplaceState = window.history.replaceState.bind(window.history);

  // Override pushState
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window.history as any).pushState = function pushStateOverride(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    unused: string,
    url?: string | URL | null
  ): void {
    originalPushState(data, unused, url);
    window.dispatchEvent(new Event("locationchange"));
  };

  // Override replaceState
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window.history as any).replaceState = function replaceStateOverride(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    unused: string,
    url?: string | URL | null
  ): void {
    originalReplaceState(data, unused, url);
    window.dispatchEvent(new Event("locationchange"));
  };

  window.addEventListener("popstate", () => {
    window.dispatchEvent(new Event("locationchange"));
  });
}
