import React, { useEffect } from "react";
import { useGliaVisibilityContext } from "./GliaVisibilityContext";

const setGliaVisibility = (visibility: boolean) => {
  const ctaElement = document.querySelector("#salemove") as HTMLElement | null;

  if (visibility) {
    ctaElement?.style?.removeProperty("display");
  } else {
    ctaElement?.style?.setProperty("display", "none", "important");
  }
};

export const GliaVisibilityHandler = () => {
  const { isGliaVisible } = useGliaVisibilityContext();

  useEffect(() => {
    setGliaVisibility(isGliaVisible);
  }, [isGliaVisible]);

  return null;
};
