import { SearchSelector, HighlightedItem, Row } from "cerulean";

interface RecipientSelectorProps {
  field: string;
  value: string;
  recipients: API.Recipient[];
  label: string;
  onChange: (recipientId: API.Recipient["id"]) => void;
  onAddRecipientClick: () => void;
  error?: string;
  displayBottomAction?: boolean;
}

const RecipientSelector = ({
  value,
  recipients,
  label,
  onChange,
  onAddRecipientClick,
  error,
  displayBottomAction = true,
}: RecipientSelectorProps) => (
  <SearchSelector<API.Recipient>
    value={value}
    items={recipients}
    label={label}
    onChange={onChange}
    filter={(recipient: API.Recipient, searchTerm: string) => {
      const searchStr = `${recipient.name} ${
        recipient.nickname || ""
      }`.toLowerCase();
      return searchStr.includes(searchTerm.toLowerCase());
    }}
    getItemLabel={(recipient: API.Recipient) => recipient.name}
    renderItem={(recipient: API.Recipient) => (
      <Row>
        <Row.Item>{`${recipient.name}`}</Row.Item>
        {recipient.nickname ? (
          <Row.Item shrink>
            <span className="fontSize--xs fontColor--secondary">
              {recipient.nickname}
            </span>
          </Row.Item>
        ) : null}
      </Row>
    )}
    renderHighlight={(recipient: API.Recipient, searchTerm: string) => (
      <Row>
        <Row.Item>
          <HighlightedItem value={recipient.name} searchTerm={searchTerm} />
        </Row.Item>
        {recipient.nickname ? (
          <Row.Item shrink>
            <span className="fontSize--xs fontColor--secondary">
              <HighlightedItem
                value={recipient.nickname}
                searchTerm={searchTerm}
              />
            </span>
          </Row.Item>
        ) : null}
      </Row>
    )}
    getItemValue={(recipient: API.Recipient) => recipient.id}
    bottomAction={
      displayBottomAction && {
        label: "+ Add a new recipient",
        onClick: onAddRecipientClick,
      }
    }
    error={error}
  />
);

export default RecipientSelector;
