import type { NetworkPayload } from "../../../composites";

export const API = {
  fetchACHCompanies: () => {
    return {
      url: "ach_companies/",
      options: {
        method: "GET",
      },
    } satisfies NetworkPayload;
  },
};
