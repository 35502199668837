import { catcat as cc, Tag } from "cerulean";
import VISA_LOGO from "../../../../assets/images/visa_logo_official.svg";
import MASTERCARD_LOGO from "../../../../assets/images/mastercard_logo_unofficial.svg";

import styles from "./CardImage.module.scss";

type CardSize = "x-small" | "small" | "large";

interface CardImageProps {
  name?: string;
  textColor: string;
  isLocked: boolean;
  image: string;
  lastFourDigits?: string;
  network: API.CardProvider;
  size?: CardSize;
}

const mastercardSizes = {
  "x-small": { bottom: "5px", right: "0px" },
  small: { bottom: "7px", right: "0px" },
  large: { bottom: "8px", right: "0px" },
} as const;

const visaSizes = {
  "x-small": { bottom: "7.53px", right: "8px" },
  small: { bottom: "11.65px", right: "12px" },
  large: { bottom: "18.01px", right: "20px" },
} as const;

const logoSize = {
  "x-small": "20px",
  small: "32px",
  large: "68px",
} as const;

type PixelString = `${number}px`;

const getCardStyle = (
  network: API.Card["network"],
  size: CardSize = "small",
) => {
  const CARD_STYLES: Record<
    API.Card["network"],
    {
      aspectRatio: number;
      logo: string;
      bottom: PixelString;
      right: PixelString;
    }
  > = {
    Mastercard: {
      aspectRatio: 152 / 108,
      logo: MASTERCARD_LOGO,
      bottom: mastercardSizes[size].bottom,
      right: mastercardSizes[size].right,
    },
    Visa: {
      aspectRatio: 1920 / 620,
      logo: VISA_LOGO,
      bottom: visaSizes[size].bottom,
      right: visaSizes[size].right,
    },
  };

  if (network in CARD_STYLES) {
    return CARD_STYLES[network];
  }

  return CARD_STYLES.Visa;
};
const CardImage = ({
  name,
  textColor,
  isLocked,
  image,
  lastFourDigits,
  size = "small",
  network,
}: CardImageProps) => {
  const { aspectRatio, logo, ...remainingStyleProps } = getCardStyle(
    network,
    size,
  );

  const width: PixelString = logoSize[size];
  const height = `${parseInt(width.split("px")[0], 10) / aspectRatio}px`;
  return (
    <div
      className={cc(styles.cardWrapper, {
        [styles.large]: size === "large",
      })}
    >
      <div
        className={cc(
          styles.cardContainer,
          { [styles.large]: size === "large" },
          { [styles.small]: ["x-small", "small"].includes(size) },
        )}
      >
        <div
          className={styles.cardSvg}
          style={{
            color: textColor,
            filter: isLocked ? "opacity(0.4)" : "",
            backgroundImage: `url('${image}')`,
          }}
        >
          {name && <span className={styles.cardName}>{name}</span>}
          {lastFourDigits && (
            <>
              <span className={styles.mask}>**</span>
              <span className={styles.lastFour}>{lastFourDigits}</span>
            </>
          )}

          <div
            className={styles.cardLogo}
            style={{
              backgroundImage: `url('${logo}')`,
              width,
              height,
              ...remainingStyleProps,
            }}
          />
        </div>
        {isLocked && (
          <div className={styles.lockedTag}>
            <Tag label="Locked" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardImage;
