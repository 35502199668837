import { useCallback } from "react";

import { useDispatch } from "react-redux";

import { useObjectMemo } from "../../../hooks";
import {
  parseErrorResponse,
  useRequestWithFeedback,
  type OverrideRunProps,
} from "../../../composites";
import { useLibrary } from "../../../providers";

import { API } from "./api";
import { actions } from "./slice";
import {
  RecipientProfileForm,
  ACHDestinationForm,
  WireDestinationForm,
  transformWireFormFieldsToApiFields,
  transformACHFormFieldsToApiFields,
} from "./forms";

import type { BootstrapFetchRequest } from "../../bootstrap";

export const useFetchRecipients = () => {
  const dispatch = useDispatch();

  const { send, loading, status } =
    useRequestWithFeedback<API.FetchRecipients.Response>();

  const fetchRecipients = useCallback(
    (overrideRunProps: OverrideRunProps<API.FetchRecipients.Response> = {}) => {
      send({
        action: API.fetchRecipients(),
        onData: ({ results: recipients }) => {
          dispatch(actions.setMany(recipients));
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send],
  );

  // when creating a hook that has an API request, has to follow the below convention
  return useObjectMemo({
    send: fetchRecipients,
    loading,
    error: status === "error",
    hasData: false,
  }) satisfies BootstrapFetchRequest;
};

export const useDeleteRecipient = () => {
  const dispatch = useDispatch();
  const { send, loading } =
    useRequestWithFeedback<API.FetchRecipients.Response>();
  const t = useLibrary("translations");

  const deleteRecipient = useCallback(
    (
      recipientId: API.RecipientId,
      overrideRunProps: OverrideRunProps<API.FetchRecipients.Response> = {},
    ) => {
      send({
        action: API.deleteRecipient(recipientId),
        onData: () => {
          dispatch(actions.deleteOne(recipientId));
        },
        messaging: {
          toast: {
            success: t.getString(
              "recipient-delete-success-banner",
              null,
              "Recipient deleted.",
            ),
            error: t.getString(
              "recipient-delete-error-banner",
              null,
              "Unable to delete recipient. Please try again.",
            ),
          },
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send, t],
  );

  return useObjectMemo({
    deleteRecipient,
    loading,
  });
};

export const useCreateOrUpdateACHDestination = () => {
  const { send, loading, errorMessage } = useRequestWithFeedback<
    API.CreateUpdateACHDestination.Response,
    API.CreateUpdateACHDestination.Error
  >();
  const dispatch = useDispatch();
  const { values } = ACHDestinationForm.useForm();

  const createOrUpdateACHDestination = useCallback(
    (
      recipient: API.Recipient,
      overrideRunProps: OverrideRunProps<
        API.CreateUpdateACHDestination.Response,
        API.CreateUpdateACHDestination.Error
      > = {},
    ) => {
      const updatedValues = transformACHFormFieldsToApiFields({
        ...values,
        recipientId: recipient.id,
      });
      send({
        action: API.createOrUpdateACHDestination(updatedValues),
        onData: (achDestination) => {
          // update the store by updating the recipient's ach destination details
          dispatch(
            actions.updateOne({
              ...recipient,
              ach_destination: achDestination,
            }),
          );
        },
        onError: async (error, setErrorMessage) => {
          const { errors } = await parseErrorResponse(error);
          if (errors.length) {
            setErrorMessage(`${errors[0].description}`);
          }
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send, values],
  );

  return useObjectMemo({
    errorMessage,
    createOrUpdateACHDestination,
    loading,
  });
};

export const useCreateOrUpdateWireDestination = () => {
  const { send, loading } = useRequestWithFeedback<
    API.CreateUpdateWireDestination.Response,
    API.CreateUpdateWireDestination.Error
  >();
  const dispatch = useDispatch();
  const { values } = WireDestinationForm.useForm();

  const createWireDestination = useCallback(
    (
      recipient: API.Recipient,
      overrideRunProps: OverrideRunProps<
        API.CreateUpdateWireDestination.Response,
        API.CreateUpdateWireDestination.Error
      > = {},
    ) => {
      const updatedValues = transformWireFormFieldsToApiFields({
        ...values,
        recipientId: recipient.id,
      });
      send({
        action: API.createWireDestination(updatedValues),
        onData: (wireDestination) => {
          dispatch(
            actions.updateOne({
              ...recipient,
              fedwire_destination: wireDestination,
            }),
          );
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send, values],
  );

  const updateWireDestination = useCallback(
    (
      recipient: API.Recipient,
      overrideRunProps: OverrideRunProps<
        API.CreateUpdateWireDestination.Response,
        API.CreateUpdateWireDestination.Error
      > = {},
    ) => {
      const updatedValues = transformWireFormFieldsToApiFields({
        ...values,
        recipientId: recipient.id,
      });
      send({
        action: API.updateWireDestination({
          ...updatedValues,
          fedwireId: recipient?.fedwire_destination
            ?.id as API.FedwireDestinationId,
        }),
        onData: (wireDestination) => {
          dispatch(
            actions.updateOne({
              ...recipient,
              fedwire_destination: wireDestination,
            }),
          );
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send, values],
  );

  return useObjectMemo({
    createWireDestination,
    updateWireDestination,
    loading,
  });
};

export const useCreateOrUpdateRecipientProfile = () => {
  const { send, loading, status, errorMessage } = useRequestWithFeedback<
    API.CreateRecipientProfile.Response,
    API.CreateRecipientProfile.Error
  >();
  const { values } = RecipientProfileForm.useForm();
  const dispatch = useDispatch();

  const createOrUpdateRecipientProfile = useCallback(
    (
      recipientId?: API.RecipientId,
      overrideRunProps: OverrideRunProps<
        API.CreateRecipientProfile.Response,
        API.CreateRecipientProfile.Error
      > = {},
    ) => {
      send({
        action: API.createOrUpdateRecipientProfile(values, recipientId),
        onData: (recipient) => {
          if (!recipientId) {
            dispatch(actions.setOne(recipient));
          } else {
            dispatch(actions.updateOne(recipient));
          }
        },
        onError: async (error, setErrorMessage) => {
          const { errors } = await parseErrorResponse(error);
          if (errors.length) {
            setErrorMessage(`${errors[0].description}`);
          }
        },
        ...overrideRunProps,
      });
    },
    [dispatch, send, values],
  );

  return useObjectMemo({
    createOrUpdateRecipientProfile,
    loading,
    error: status === "error",
    errorMessage,
  });
};
