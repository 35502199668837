import { useEffect, useState, useMemo } from "react";
import { useLocalization } from "@fluent/react";
import {
  Button,
  ContentCard,
  MenuButton,
  LoadingSkeleton,
  Row,
  useLoadingContext,
  useNotificationContext,
} from "cerulean";
import Account from "byzantine/src/Account";
import MemberFriend from "byzantine/src/MemberFriend";
import DeleteDialog from "./DeleteDialog";
import NewMemberModal from "../../transfer/NewMemberModal";

interface ManageFriendPopoverProps {
  openDeleteDialog: () => void;
}

const ManageFriendPopover = ({
  openDeleteDialog,
}: ManageFriendPopoverProps) => (
  <MenuButton triggerIcon="more-vertical">
    <MenuButton.Item
      label="Delete"
      startIcon="trash-2"
      onSelect={openDeleteDialog}
    />
  </MenuButton>
);

interface MemberFriendCardProps {
  memberFriend: MemberFriend;
  fetchMemberFriends: () => Promise<void>;
}

const MemberFriendCard = ({
  memberFriend,
  fetchMemberFriends,
}: MemberFriendCardProps) => {
  const [isDeleteMemberDialogOpen, setIsDeleteMemberDialogOpen] =
    useState(false);
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();
  const { l10n } = useLocalization();

  const deleteMember = async () => {
    setIsDeleteMemberDialogOpen(false);
    setIsLoading(true);
    try {
      await memberFriend.delete();
      setIsLoading(false);
      sendNotification({
        type: "success",
        text: l10n.getString("member-deleted"),
      });
      await fetchMemberFriends();
    } catch {
      sendNotification({
        type: "negative",
        text: l10n.getString("member-not-deleted"),
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <ContentCard>
        <Row>
          <Row.Item>
            <div className="fontColor--heading fontWeight--semibold">
              {memberFriend.name}
            </div>
          </Row.Item>
          <Row.Item shrink>
            <div style={{ marginTop: "-4px" }}>
              <ManageFriendPopover
                openDeleteDialog={() => setIsDeleteMemberDialogOpen(true)}
              />
            </div>
          </Row.Item>
        </Row>
        <div className="margin--top--xxs">
          {memberFriend.displayMaskedAccountNumber()}
        </div>
      </ContentCard>
      <DeleteDialog
        recipientName={memberFriend.name}
        recipientType={l10n.getString("member-or-friend")}
        onDelete={deleteMember}
        isOpen={isDeleteMemberDialogOpen}
        closeDialog={() => setIsDeleteMemberDialogOpen(false)}
      />
    </>
  );
};

const MemberFriendSection = ({ accounts }: { accounts: Account[] }) => {
  const [memberFriends, setMemberFriends] = useState<MemberFriend[]>([]);
  const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] = useState(false);
  const [isFetchingMembers, setIsFetchingMembers] = useState(false);
  const { l10n } = useLocalization();

  const { sendNotification } = useNotificationContext();
  const fetchMemberFriends = async () => {
    setIsFetchingMembers(true);
    try {
      setMemberFriends((await MemberFriend.getMemberFriends()) || []);
    } catch {
      sendNotification({
        type: "negative",
        text: l10n.getString("member-fetch-fail"),
      });
    } finally {
      setIsFetchingMembers(false);
    }
  };
  useEffect(() => {
    fetchMemberFriends();
  }, []);

  const validTransferAccounts = useMemo(
    () => accounts.filter((a) => a.isInternal() && a.isValidTransferSource()),
    [accounts],
  );

  return (
    <>
      <div className="margin--top--xl margin--bottom--l">
        <Row>
          <Row.Item>
            <div className="fontSize--l fontColor--heading fontWeight--bold">
              {l10n.getString("members-label")}
            </div>
          </Row.Item>
          <Row.Item shrink>
            <Button
              kind="plain"
              label={l10n.getString("member-add-label")}
              onClick={() => setIsAddMemberDialogOpen(true)}
            />
          </Row.Item>
        </Row>
      </div>
      <LoadingSkeleton content="paragraph" isLoading={isFetchingMembers}>
        <div className="recipients-container">
          {memberFriends?.map((member) => (
            <MemberFriendCard
              key={member.id}
              memberFriend={member}
              fetchMemberFriends={fetchMemberFriends}
            />
          ))}
          {!memberFriends?.length && (
            <div>{l10n.getString("member-empty")}</div>
          )}
        </div>
      </LoadingSkeleton>
      <NewMemberModal
        accounts={validTransferAccounts}
        open={isAddMemberDialogOpen}
        handleClose={() => {
          setIsAddMemberDialogOpen(false);
        }}
        newMemberAdded={() => {
          sendNotification({
            type: "success",
            text: l10n.getString("member-added"),
          });
          fetchMemberFriends();
        }}
      />
    </>
  );
};

export default MemberFriendSection;
