import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";

const Details = ({ summary, children, type, ariaLabel }) => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(isOpen => !isOpen);
  };

  const onFocus = (e) => {
    const isKeyboardEvent = e.nativeEvent.sourceCapabilities?.firesTouchEvents;
    if (!isKeyboardEvent && !open) {
      setOpen(true)
    }
  };

  const onBlur = () => {
    if (open) {
      setOpen(false)
    }
  }

  return (
    <div className="nds-details nds-button navbar-nds-btn">
      <div
        role="button"
        tabIndex="0"
        className="nds-details-summary"
        aria-label={ariaLabel}
        onKeyUp={({ key }) => {
          if (key === "Enter") {
            toggleMenu();
          }
        }}
        onClick={toggleMenu}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {summary}
        <div
          className={`chevron narmi-icon-chevron-down${open ? " open" : ""}`}
        />
      </div>
      <Modal type={type} open={open} setOpen={setOpen}>
        <div
          className={`nds-details-container ${
            type === "wide details" ? "narmi-grid-container" : ""
          }`}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          <div className={type === "wide details" ? "narmi-grid-content" : ""}>
            {children}
          </div>
        </div>
      </Modal>
    </div>
  );
};

Details.propTypes = {
  summary: PropTypes.node,
  children: PropTypes.node,
  ariaLabel: PropTypes.string,
  type: PropTypes.oneOf(["details", "wide details", "medium details"]),
};

Details.defaultProps = {
  summary: null,
  type: "details",
};

export default Details;
