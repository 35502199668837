import { useCallback, useMemo } from "react";

import { DateTime } from "luxon";
import { useSelector } from "react-redux";

import { useObjectMemo } from "../../../hooks";
import { useFeature } from "../features";
import {
  useBAccount,
  recipients,
  useInstitution,
  achCompanies,
} from "../../entities";
import { useLibrary } from "../../../providers";
import filters from "../../../../filters";

import { ACHPaymentForm } from "./ACHPayment.form";
import { useManageRecipientPermission } from "./permissions";

export const useSECCodes = () => {
  const { features } = useFeature();
  return features.allowed_std_ent_cls_codes || [];
};

export const usePaymentDetails = () => {
  const institution = useInstitution();
  const sudoModeRequired = institution?.sudo_mode_required_for_ach_payments;

  const form = ACHPaymentForm.useForm();
  const { submitForm } = form;

  const recipient = recipients.useSelectedRecipient();

  const { clearAllToasts } = useLibrary("toasts");
  const onContinue = useCallback(
    async (next: () => void, trySudo: () => void) => {
      const { success } = await submitForm();
      if (!success) {
        return null;
      }
      clearAllToasts();
      if (!sudoModeRequired) {
        return next();
      }

      return trySudo();
    },
    [clearAllToasts, submitForm, sudoModeRequired],
  );

  return useObjectMemo({
    onContinue,
    recipient,
    form,
  });
};

export const useReviewDetails = () => {
  const {
    values: { fromAccount, amount, secCode },
  } = ACHPaymentForm.useForm();

  const achCompany = achCompanies.useACHCompany();
  const recipient = recipients.useSelectedRecipient();
  const account = useBAccount(fromAccount as API.AccountId);
  const t = useLibrary("translations");

  return useMemo(() => {
    if (!recipient || !account || !amount || !secCode) {
      return null;
    }

    type Section = {
      header: string;
      rows: Array<{ header: string; value: string }>;
    };

    const unknown = t.getString("ach-payment-review-unknown", {}, "Unknown");

    const paymentDetails = {
      header: t.getString(
        "ach-payment-review-payment-details-header",
        {},
        "Payment details",
      ),
      rows: [
        {
          header: t.getString("ach-payment-review-from ", {}, "From"),
          value: account.getShortDescription(false),
        },
        {
          header: t.getString(
            "ach-payment-review-company",
            {},
            "Company name / ID",
          ),
          value: achCompany
            ? `${achCompany.company_name} / ${achCompany.company_id}`
            : unknown,
        },
        {
          header: t.getString("ach-payment-review-date", {}, "Send date"),
          value: DateTime.now().toFormat("MM/dd/yyyy"),
        },
        {
          header: t.getString(
            "ach-payment-review-transaction-type",
            {},
            "Transaction type",
          ),
          value: t.getString(
            "ach-payment-review-sec-code",
            { secCode },
            `${secCode} payment`,
          ),
        },
      ],
    };

    const recipientDetails = {
      header: t.getString(
        "ach-payment-review-recipient-details-header",
        {},
        "Recipient details",
      ),
      rows: [
        {
          header: t.getString("ach-payment-review-recipient", {}, "Recipient"),
          value: recipient.name,
        },
        {
          header: t.getString("ach-payment-review-bank", {}, "Bank"),
          value: recipient.ach_destination?.institution_name || unknown,
        },
        {
          header: t.getString(
            "ach-payment-review-account",
            {},
            "Account number",
          ),
          value: recipient.ach_destination?.account_number || unknown,
        },
        {
          header: t.getString(
            "ach-payment-review-routing",
            {},
            "Routing account",
          ),
          value: recipient.ach_destination?.routing_number || unknown,
        },
      ],
    };

    const sections: Array<Section> = [paymentDetails, recipientDetails];

    return {
      sections,
      buttonLabel: t.getString("ach-payment-review-action", {}, "Make payment"),
      amount: filters.currency(amount),
      recipient,
    };
  }, [account, achCompany, amount, recipient, secCode, t]);
};

export const usePermissionedRecipients = () => {
  const hasManageRecipientPermission = useManageRecipientPermission();
  const permissionedRecipients = useSelector(recipients.selectSortedRecipients);

  return permissionedRecipients.filter(
    (r) => hasManageRecipientPermission || !!r.ach_destination,
  );
};
