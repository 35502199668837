/* eslint-disable camelcase */

import { useContext, useState } from "react";
import { Tabs } from "@narmi/design_system";
import { ContentCard, Dialog, Row, formatNumber } from "cerulean";
import { featureEnabled } from "byzantine/src/Feature";
import { InstitutionFeatures } from "byzantine/src/types";
import OrganizationUser from "byzantine/src/OrganizationUser";
import InstitutionSettingsContext from "../contexts/InstitutionSettingsContext";

type SubUserLimitsDialog = {
  userWireLimits: OrganizationUser[];
  userACHLimits: OrganizationUser[];
  userUnverifiedACHLimits: OrganizationUser[];
  isOpen: boolean;
  onClose: () => void;
  features: InstitutionFeatures;
};

const SubUserLimitsDialog = ({
  userWireLimits,
  userACHLimits,
  userUnverifiedACHLimits,
  isOpen,
  onClose,
  features,
}: SubUserLimitsDialog) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { ach_payment_disclosure } = useContext(InstitutionSettingsContext);
  const wireEnabled = featureEnabled(features, { or: ["wires"] });
  const achEnabled = featureEnabled(features, { or: ["ach"] });
  const achPaymentEnabled = featureEnabled(features, { or: ["ach_payments"] });

  const renderOrgDisclosureString = (key: string) => {
    switch (key) {
      case "ach_push":
        return "This is the amount your organization is allowed to transfer to your external accounts during the specified time period. The sum of individual user limits cannot exceed this amount.";
      case "wire":
        return "This is the amount your organization is allowed to send during the specified time period. The sum of individual user limits cannot exceed this amount.";
      case "unverified_ach_push":
        return ach_payment_disclosure;
      default:
        return "";
    }
  };

  const renderOrgLimits = (key: string) => {
    const orgLimits = features.limits?.[key]
      ? Object.entries(features.limits?.[key])
      : [];

    return (
      <>
        {orgLimits.length > 0 && (
          <div className="margin--top--l">
            <ContentCard kind="bordered">
              <h3 className="fontSize--m fontFamily--body padding--bottom--xs">
                Organizational limit
              </h3>
              <div className="margin--bottom--l">
                {renderOrgDisclosureString(key)}
              </div>
              <div className="access-manager-limits">
                {orgLimits.map(([time, value]) => (
                  <div className="subuser-limits" key={`${key}_${time}`}>
                    <Row>
                      <Row.Item>
                        <div>{time.toString()} day limit</div>
                      </Row.Item>
                      <Row.Item shrink>
                        {formatNumber(value, "currency")}
                      </Row.Item>
                    </Row>
                  </div>
                ))}
              </div>
            </ContentCard>
          </div>
        )}
      </>
    );
  };

  const getUserLimits = (key: string) => {
    switch (key) {
      case "ach_push":
        return userACHLimits;
      case "wire":
        return userWireLimits;
      case "unverified_ach_push":
        return userUnverifiedACHLimits;
      default:
        return [];
    }
  };

  const renderUserLimits = (key: string) => {
    const userLimits = getUserLimits(key);

    return (
      <>
        {userLimits.length > 0 && (
          <div className="margin--top--l">
            <ContentCard kind="bordered">
              <h3 className="fontSize--m fontFamily--body padding--bottom--xs">
                Individual user limits
              </h3>
              <div className="margin--bottom--l">
                These are the amounts each user is allowed to send as long as
                the org limit allows it. Individual limits cannot exceed the
                organizational limit during the specified time period.
              </div>
              <div className="access-manager-limits">
                {userLimits.map((orgUser, index) => (
                  <div className="subuser-limits" key={`subuser__${index}`}>
                    <Row>
                      <Row.Item>
                        <div>
                          {orgUser.user.first_name} {orgUser.user.last_name}
                        </div>
                      </Row.Item>
                      <Row.Item shrink>
                        {formatNumber(
                          orgUser.role.limits?.[key]?.[1],
                          "currency",
                        )}
                        /day
                      </Row.Item>
                    </Row>
                  </div>
                ))}
              </div>
            </ContentCard>
          </div>
        )}
      </>
    );
  };

  return (
    <Dialog isOpen={isOpen} title="Limits" onUserDismiss={onClose}>
      <div className="margin--top--s">
        <Tabs
          onTabChange={(index: number) => setSelectedTabIndex(index)}
          selectedIndex={selectedTabIndex}
        >
          <Tabs.List>
            {wireEnabled ? <Tabs.Tab label="Wire" tabId="wire" /> : null}
            {achEnabled ? <Tabs.Tab label="ACH transfer" tabId="ach" /> : null}
            {achPaymentEnabled ? (
              <Tabs.Tab label="ACH payment" tabId="ach-payment" />
            ) : null}
          </Tabs.List>
          <Tabs.Panel tabId="wire">
            <div className="margin--top--s">
              {renderOrgLimits("wire")}
              {renderUserLimits("wire")}
            </div>
          </Tabs.Panel>
          <Tabs.Panel tabId="ach">
            <div className="margin--top--s">
              {renderOrgLimits("ach_push")}
              {renderUserLimits("ach_push")}
            </div>
          </Tabs.Panel>
          {/* this is the same content as the ach dialog from the ach payment flow */}
          <Tabs.Panel tabId="ach-payment">
            <div className="margin--top--s">
              {renderOrgLimits("unverified_ach_push")}
              {renderUserLimits("unverified_ach_push")}
            </div>
          </Tabs.Panel>
        </Tabs>
      </div>
    </Dialog>
  );
};

export default SubUserLimitsDialog;
