import { masks } from "../../../utils";

export const getDestinationAccountPreview = ({
  account_number: accountNumber,
  destination_institution_name: destinationInstitution,
}: API.WireRecipient) => {
  return masks.getDestinationAccountPreview({
    accountNumber,
    destinationInstitution,
  });
};
