import { createForm } from "../../forms";

import type { CARD_LIMIT_TYPE } from "../../entities/cardLimitIncrease";

export type CardLimitIncreaseType = {
  atmLimit: number | null;
  posLimit: number | null;
  limitsEnabled: CARD_LIMIT_TYPE[];
};

const initialValues: CardLimitIncreaseType = {
  limitsEnabled: [],
  posLimit: null,
  atmLimit: null,
};

export const cardLimitIncreaseForm = createForm<CardLimitIncreaseType>({
  initialValues,
});
