import { Navigate, Routes, Route } from "react-router-dom";

import { catcat as cc, Drawer, useBreakpoints } from "cerulean";

import styles from "./CardManagerDrawer.module.scss";
import ManageACard from "./ManageACard/ManageACard";
import TravelNotice from "./TravelNotice/TravelNotice";

import LimitIncrease from "./LimitIncrease/LimitIncrease";

export interface CardManagerDrawerProps {
  isOpen: boolean;
  card: API.Card | null;
  onUserDismiss: () => void;
  updateCardStateById: (cardId: string, cardState: API.Card["state"]) => void;
}

const CardManagerDrawer = ({
  isOpen,
  card,
  onUserDismiss,
  updateCardStateById,
}: CardManagerDrawerProps) => {
  const { m } = useBreakpoints();

  if (isOpen && !card) {
    return <Navigate to="/cards" replace={true} />;
  }

  if (!card) {
    return null;
  }

  return (
    <Drawer
      depth="470px"
      isOpen={isOpen}
      onUserDismiss={onUserDismiss}
      showControls={false}
      paddingSize="none"
    >
      <Routes>
        <Route
          path="/"
          element={
            <div className={cc({ "padding--all--xl": m })}>
              <div className={styles.cardManagerDrawerContainer}>
                <ManageACard
                  card={card}
                  onUserDismiss={onUserDismiss}
                  updateCardStateById={updateCardStateById}
                />
              </div>
            </div>
          }
        />
        <Route
          path="/travel_notice"
          element={<TravelNotice card={card} onUserDismiss={onUserDismiss} />}
        />
        <Route
          path="/limit_increase"
          element={<LimitIncrease card={card} onUserDismiss={onUserDismiss} />}
        />
      </Routes>
    </Drawer>
  );
};

export default CardManagerDrawer;
